"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  Biome: () => Biome,
  COMMON_TEMPERAMENT_SET: () => COMMON_TEMPERAMENT_SET,
  EdDSAFrogPCD: () => EdDSAFrogPCD2,
  EdDSAFrogPCDPackage: () => EdDSAFrogPCDPackage,
  EdDSAFrogPCDTypeName: () => EdDSAFrogPCDTypeName,
  Rarity: () => Rarity,
  Temperament: () => Temperament,
  deserialize: () => deserialize,
  frogDataToBigInts: () => frogDataToBigInts,
  getDisplayOptions: () => getDisplayOptions,
  getEdDSAFrogData: () => getEdDSAFrogData,
  getPublicKey: () => getPublicKey,
  initArgs: () => initArgs,
  isEdDSAFrogPCD: () => isEdDSAFrogPCD,
  prove: () => prove,
  semaphoreIdToBigInt: () => semaphoreIdToBigInt,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/EdDSAFrogPCD.ts
var import_eddsa_pcd = require("@pcd/eddsa-pcd");
var import_pcd_types = require("@pcd/pcd-types");
var import_json_bigint = __toESM(require("json-bigint"));
var import_lodash2 = __toESM(require("lodash"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_lodash = __toESM(require("lodash"));
var import_react = require("react");
var import_styled_components = __toESM(require("styled-components"));

// src/utils.ts
var import_util = require("@pcd/util");
function semaphoreIdToBigInt(v) {
  return BigInt(v);
}
function frogDataToBigInts(data) {
  return [
    (0, import_util.numberToBigInt)(data.frogId),
    (0, import_util.numberToBigInt)(data.biome),
    (0, import_util.numberToBigInt)(data.rarity),
    (0, import_util.numberToBigInt)(data.temperament),
    (0, import_util.numberToBigInt)(data.jump),
    (0, import_util.numberToBigInt)(data.speed),
    (0, import_util.numberToBigInt)(data.intelligence),
    (0, import_util.numberToBigInt)(data.beauty),
    (0, import_util.numberToBigInt)(data.timestampSigned),
    semaphoreIdToBigInt(data.ownerSemaphoreId),
    (0, import_util.numberToBigInt)(0),
    (0, import_util.numberToBigInt)(0),
    (0, import_util.numberToBigInt)(0)
  ];
}
function getEdDSAFrogData(pcd) {
  var _a;
  return (_a = pcd == null ? void 0 : pcd.claim) == null ? void 0 : _a.data;
}
function getPublicKey(pcd) {
  var _a, _b, _c;
  return (_c = (_b = (_a = pcd == null ? void 0 : pcd.proof) == null ? void 0 : _a.eddsaPCD) == null ? void 0 : _b.claim) == null ? void 0 : _c.publicKey;
}

// src/CardBody.tsx
var import_jsx_runtime = require("react/jsx-runtime");
function EdDSAFrogCardBody({
  pcd,
  returnHeader
}) {
  var _a;
  const frogData = (0, import_react.useMemo)(() => getEdDSAFrogData(pcd), [pcd]);
  const [showMore, setShowMore] = (0, import_react.useState)(false);
  const [showPCD, setShowPCD] = (0, import_react.useState)(false);
  if (returnHeader) {
    if (!frogData) {
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_jsx_runtime.Fragment, { children: "Frog" });
    }
    if (frogData.rarity === 5 /* Mythic */) {
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(MythicContainer, { children: `#${frogData.frogId} ${frogData.name}` });
    }
    const rarityColors = {
      [1 /* Common */]: "#2D9061",
      [2 /* Rare */]: "#1197B5",
      [3 /* Epic */]: "#6F3BB0",
      [4 /* Legendary */]: "#FF9900",
      [0 /* Unknown */]: "#A7967E",
      [6 /* Object */]: "#A7967E"
    };
    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      HeaderContainer,
      {
        style: {
          backgroundColor: (_a = rarityColors[frogData.rarity]) != null ? _a : "var(--bg-dark-primary)"
        },
        children: `#${frogData.frogId} ${frogData.name}`
      }
    );
  }
  if (!frogData) {
    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Container, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FrogQR, { pcd }) });
  }
  return showPCD ? /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.LinkButton, { onClick: () => setShowPCD(false), children: "View as frog" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FrogQR, { pcd }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(CopyFrogPCD, { pcd })
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      import_passport_ui.LinkButton,
      {
        style: { textAlign: "center" },
        onClick: () => setShowPCD(true),
        children: "View as proof\u2011carrying data"
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      import_passport_ui.ImageZoom,
      {
        src: frogData == null ? void 0 : frogData.imageUrl,
        draggable: false,
        loading: "lazy",
        style: { width: "100%", height: "auto", zIndex: "1000" },
        options: {
          background: "rgba(0, 0, 0, 0.5)"
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(FrogInfo, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FrogAttribute, { label: "JMP", title: "Jump", value: frogData.jump }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        FrogAttribute,
        {
          label: "VIB",
          title: "Vibe",
          value: temperamentValue(frogData.temperament)
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FrogAttribute, { label: "SPD", title: "Speed", value: frogData == null ? void 0 : frogData.speed }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        FrogAttribute,
        {
          label: "INT",
          title: "Intelligence",
          value: frogData.intelligence
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FrogAttribute, { label: "BTY", title: "Beauty", value: frogData.beauty })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.LinkButton, { onClick: () => setShowMore(!showMore), children: showMore ? "Collapse" : "See more" }),
    showMore && /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Description, { children: frogData.description }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(FrogInfo, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
          FrogAttribute,
          {
            label: "Signed at",
            title: `Signed at: ${frogData.timestampSigned}`,
            value: new Date(frogData.timestampSigned).toLocaleDateString()
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
          FrogAttribute,
          {
            label: "Source",
            title: "Biome",
            value: biomeValue(frogData.biome)
          }
        )
      ] })
    ] })
  ] });
}
function FrogAttribute({
  label,
  title,
  value
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Attribute, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(AttrTitle, { title, children: label }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(AttrValue, { style: { color: attrColor(value) }, children: formatAttrValue(value) })
  ] });
}
function attrColor(value) {
  if (typeof value === "number") {
    if (value <= 3) {
      return "#a95940";
    }
    if (value >= 7) {
      return "#206b5e";
    }
  }
}
function formatAttrValue(value) {
  if (typeof value === "number") {
    return String(value).padStart(2, "0");
  }
  return value;
}
function temperamentValue(temperament) {
  switch (temperament) {
    case 0 /* UNKNOWN */:
      return "???";
    case 1 /* N_A */:
      return "N/A";
    default:
      return Temperament[temperament];
  }
}
function biomeValue(biome) {
  return import_lodash.default.startCase(Biome[biome]);
}
function FrogQR({ pcd }) {
  const [hex, setHex] = (0, import_react.useState)("");
  const generate = (0, import_react.useCallback)(() => __async(this, null, function* () {
    const serialized = yield EdDSAFrogPCDPackage.serialize(pcd);
    return (0, import_passport_ui.encodeQRPayload)(JSON.stringify(serialized));
  }), [pcd]);
  (0, import_react.useEffect)(() => {
    generate().then(setHex);
  }, [generate]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(HexContainer, { children: hex });
}
function CopyFrogPCD({ pcd }) {
  const [copied, setCopied] = (0, import_react.useState)(false);
  const onClick = (0, import_react.useCallback)(() => __async(this, null, function* () {
    const serialized = yield EdDSAFrogPCDPackage.serialize(pcd);
    navigator.clipboard.writeText(JSON.stringify(serialized));
    setCopied(true);
    setTimeout(() => setCopied(false), 1e3);
  }), [pcd]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.LinkButton, { onClick, children: copied ? "Copied!" : "Copy frog PCD" });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;
var FrogInfo = import_styled_components.default.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
`;
var Description = import_styled_components.default.div`
  font-size: 14px;
  color: rgba(var(--black-rgb), 0.8);
`;
var Attribute = import_styled_components.default.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-family: monospace;
`;
var AttrTitle = import_styled_components.default.div`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
`;
var AttrValue = import_styled_components.default.div`
  font-size: 14px;
`;
var HexContainer = import_styled_components.default.div`
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow-wrap: anywhere;
  max-height: 300px;
  overflow-y: auto;
`;
var HeaderContainer = import_styled_components.default.div`
  text-align: center;
  margin: -10px;
  padding: 10px;
  color: white;
  font-weight: bold;
`;
var MythicContainer = (0, import_styled_components.default)(HeaderContainer)`
  @keyframes SAHGlowingGradient {
    0% {
      background-position: 84% 0;
    }
    50% {
      background-position: 17% 100%;
    }
    100% {
      background-position: 84% 0;
    }
  }

  appearance: none;
  background: linear-gradient(
    48deg,
    #e3c1f4,
    #d9d7ed,
    #dff7f1,
    #acf0ff,
    #e3c1f4,
    #d9d7ed,
    #dff7f1,
    #acf0ff,
    #e3c1f4
  );
  animation: SAHGlowingGradient 8s ease infinite;
  opacity: 1;
  background-size: 400% 400%;

  color: #fda7a7;
  text-shadow: 0px 1px 2px #129191;
`;

// src/EdDSAFrogPCD.ts
var EdDSAFrogPCDTypeName = "eddsa-frog-pcd";
var Biome = /* @__PURE__ */ ((Biome2) => {
  Biome2[Biome2["Unknown"] = 0] = "Unknown";
  Biome2[Biome2["Jungle"] = 1] = "Jungle";
  Biome2[Biome2["Desert"] = 2] = "Desert";
  Biome2[Biome2["Swamp"] = 3] = "Swamp";
  Biome2[Biome2["TheCapital"] = 4] = "TheCapital";
  Biome2[Biome2["PutridSwamp"] = 5] = "PutridSwamp";
  Biome2[Biome2["CelestialPond"] = 6] = "CelestialPond";
  Biome2[Biome2["TheWrithingVoid"] = 7] = "TheWrithingVoid";
  return Biome2;
})(Biome || {});
var Rarity = /* @__PURE__ */ ((Rarity2) => {
  Rarity2[Rarity2["Unknown"] = 0] = "Unknown";
  Rarity2[Rarity2["Common"] = 1] = "Common";
  Rarity2[Rarity2["Rare"] = 2] = "Rare";
  Rarity2[Rarity2["Epic"] = 3] = "Epic";
  Rarity2[Rarity2["Legendary"] = 4] = "Legendary";
  Rarity2[Rarity2["Mythic"] = 5] = "Mythic";
  Rarity2[Rarity2["Object"] = 6] = "Object";
  return Rarity2;
})(Rarity || {});
var Temperament = /* @__PURE__ */ ((Temperament2) => {
  Temperament2[Temperament2["UNKNOWN"] = 0] = "UNKNOWN";
  Temperament2[Temperament2["N_A"] = 1] = "N_A";
  Temperament2[Temperament2["ANGY"] = 2] = "ANGY";
  Temperament2[Temperament2["BORD"] = 3] = "BORD";
  Temperament2[Temperament2["CALM"] = 4] = "CALM";
  Temperament2[Temperament2["CHUB"] = 5] = "CHUB";
  Temperament2[Temperament2["COOL"] = 6] = "COOL";
  Temperament2[Temperament2["DARK"] = 7] = "DARK";
  Temperament2[Temperament2["DOOM"] = 8] = "DOOM";
  Temperament2[Temperament2["HMBL"] = 9] = "HMBL";
  Temperament2[Temperament2["HNGY"] = 10] = "HNGY";
  Temperament2[Temperament2["HRNY"] = 11] = "HRNY";
  Temperament2[Temperament2["HYPE"] = 12] = "HYPE";
  Temperament2[Temperament2["MEOW"] = 13] = "MEOW";
  Temperament2[Temperament2["OKAY"] = 14] = "OKAY";
  Temperament2[Temperament2["PUFF"] = 15] = "PUFF";
  Temperament2[Temperament2["SADG"] = 16] = "SADG";
  Temperament2[Temperament2["SLLY"] = 17] = "SLLY";
  Temperament2[Temperament2["SLPY"] = 18] = "SLPY";
  Temperament2[Temperament2["WISE"] = 19] = "WISE";
  Temperament2[Temperament2["WOW"] = 20] = "WOW";
  Temperament2[Temperament2["YOLO"] = 21] = "YOLO";
  return Temperament2;
})(Temperament || {});
var COMMON_TEMPERAMENT_SET = [
  10 /* HNGY */,
  2 /* ANGY */,
  16 /* SADG */,
  4 /* CALM */,
  3 /* BORD */,
  7 /* DARK */,
  18 /* SLPY */,
  4 /* CALM */
];
var initArgs;
function init(args) {
  return __async(this, null, function* () {
    initArgs = args;
  });
}
var EdDSAFrogPCD2 = class {
  constructor(id, claim, proof) {
    this.type = EdDSAFrogPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function prove(args) {
  return __async(this, null, function* () {
    var _a;
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    if (!args.privateKey.value) {
      throw new Error("missing private key");
    }
    if (!args.data.value) {
      throw new Error("missing data value");
    }
    const seralizedData = frogDataToBigInts(args.data.value);
    const eddsaPCD = yield import_eddsa_pcd.EdDSAPCDPackage.prove({
      message: {
        value: seralizedData.map((b) => b.toString()),
        argumentType: import_pcd_types.ArgumentTypeName.StringArray
      },
      privateKey: {
        value: args.privateKey.value,
        argumentType: import_pcd_types.ArgumentTypeName.String
      },
      id: {
        value: void 0,
        argumentType: import_pcd_types.ArgumentTypeName.String
      }
    });
    const id = (_a = args.id.value) != null ? _a : (0, import_uuid.v4)();
    return new EdDSAFrogPCD2(id, { data: args.data.value }, { eddsaPCD });
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    const messageDerivedFromClaim = frogDataToBigInts(pcd.claim.data);
    return import_lodash2.default.isEqual(messageDerivedFromClaim, pcd.proof.eddsaPCD.claim.message) && import_eddsa_pcd.EdDSAPCDPackage.verify(pcd.proof.eddsaPCD);
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    const serializedEdDSAPCD = yield import_eddsa_pcd.EdDSAPCDPackage.serialize(
      pcd.proof.eddsaPCD
    );
    return {
      type: EdDSAFrogPCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify({
        id: pcd.id,
        eddsaPCD: serializedEdDSAPCD,
        data: pcd.claim.data
      })
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    const deserializedWrapper = (0, import_json_bigint.default)().parse(serialized);
    const deserializedEdDSAPCD = yield import_eddsa_pcd.EdDSAPCDPackage.deserialize(
      deserializedWrapper.eddsaPCD.pcd
    );
    return new EdDSAFrogPCD2(
      deserializedWrapper.id,
      { data: deserializedWrapper.data },
      { eddsaPCD: deserializedEdDSAPCD }
    );
  });
}
function getDisplayOptions(pcd) {
  if (!initArgs) {
    throw new Error("package not initialized");
  }
  const frogData = getEdDSAFrogData(pcd);
  if (!frogData) {
    return {
      header: "Frog",
      displayName: "frog-" + pcd.id.substring(0, 4)
    };
  }
  return {
    displayName: `#${String(frogData.frogId).padStart(3, "00")} ${frogData.name}`
  };
}
function isEdDSAFrogPCD(pcd) {
  return pcd.type === EdDSAFrogPCDTypeName;
}
var EdDSAFrogPCDPackage = {
  name: EdDSAFrogPCDTypeName,
  renderCardBody: EdDSAFrogCardBody,
  getDisplayOptions,
  init,
  prove,
  verify,
  serialize,
  deserialize
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Biome,
  COMMON_TEMPERAMENT_SET,
  EdDSAFrogPCD,
  EdDSAFrogPCDPackage,
  EdDSAFrogPCDTypeName,
  Rarity,
  Temperament,
  deserialize,
  frogDataToBigInts,
  getDisplayOptions,
  getEdDSAFrogData,
  getPublicKey,
  initArgs,
  isEdDSAFrogPCD,
  prove,
  semaphoreIdToBigInt,
  serialize,
  verify
});
