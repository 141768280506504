"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  EmailPCD: () => EmailPCD,
  EmailPCDPackage: () => EmailPCDPackage,
  EmailPCDTypeName: () => EmailPCDTypeName,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  getEmailAddress: () => getEmailAddress,
  getPublicKey: () => getPublicKey,
  prove: () => prove,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/EmailPCD.ts
var import_eddsa_pcd = require("@pcd/eddsa-pcd");
var import_pcd_types = require("@pcd/pcd-types");
var import_util = require("@pcd/util");
var import_json_bigint = __toESM(require("json-bigint"));
var import_lodash = __toESM(require("lodash"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_styled_components = __toESM(require("styled-components"));

// src/utils.ts
function getEmailAddress(pcd) {
  var _a;
  return (_a = pcd == null ? void 0 : pcd.claim) == null ? void 0 : _a.emailAddress;
}
function getPublicKey(pcd) {
  var _a, _b, _c;
  return (_c = (_b = (_a = pcd == null ? void 0 : pcd.proof) == null ? void 0 : _a.eddsaPCD) == null ? void 0 : _b.claim) == null ? void 0 : _c.publicKey;
}

// src/CardBody.tsx
var import_jsx_runtime = require("react/jsx-runtime");
function EmailCardBody({ pcd }) {
  const emailAddress = getEmailAddress(pcd);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Container, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(EmailInfo, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("span", { children: emailAddress }) }) });
}
var Container = import_styled_components.default.span`
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
var EmailInfo = import_styled_components.default.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// src/EmailPCD.ts
var EmailPCDTypeName = "email-pcd";
var EmailPCD = class {
  constructor(id, claim, proof) {
    this.type = EmailPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function prove(args) {
  return __async(this, null, function* () {
    var _a;
    if (!args.privateKey.value) {
      throw new Error("missing private key");
    }
    if (!args.emailAddress.value) {
      throw new Error("missing email value");
    }
    if (!args.semaphoreId.value) {
      throw new Error("missing semaphore id");
    }
    const hashedEmail = (0, import_util.generateSnarkMessageHash)(args.emailAddress.value);
    const eddsaPCD = yield import_eddsa_pcd.EdDSAPCDPackage.prove({
      message: {
        value: [hashedEmail.toString(), args.semaphoreId.value],
        argumentType: import_pcd_types.ArgumentTypeName.StringArray
      },
      privateKey: {
        value: args.privateKey.value,
        argumentType: import_pcd_types.ArgumentTypeName.String
      },
      id: {
        value: args.id.value ? args.id.value + "-signature" : void 0,
        argumentType: import_pcd_types.ArgumentTypeName.String
      }
    });
    const id = (_a = args.id.value) != null ? _a : (0, import_uuid.v4)();
    return new EmailPCD(
      id,
      {
        emailAddress: args.emailAddress.value,
        semaphoreId: args.semaphoreId.value
      },
      { eddsaPCD }
    );
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    const messageDerivedFromClaim = (0, import_util.generateSnarkMessageHash)(
      pcd.claim.emailAddress
    );
    if (!import_lodash.default.isEqual(
      [messageDerivedFromClaim, BigInt(pcd.claim.semaphoreId)],
      pcd.proof.eddsaPCD.claim.message
    )) {
      return false;
    }
    try {
      const valid = yield import_eddsa_pcd.EdDSAPCDPackage.verify(pcd.proof.eddsaPCD);
      return valid;
    } catch (e) {
      return false;
    }
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    const serializedEdDSAPCD = yield import_eddsa_pcd.EdDSAPCDPackage.serialize(
      pcd.proof.eddsaPCD
    );
    return {
      type: EmailPCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify({
        id: pcd.id,
        eddsaPCD: serializedEdDSAPCD,
        emailAddress: pcd.claim.emailAddress,
        semaphoreId: pcd.claim.semaphoreId
      })
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const deserializedWrapper = (0, import_json_bigint.default)().parse(serialized);
    const deserializedEdDSAPCD = yield import_eddsa_pcd.EdDSAPCDPackage.deserialize(
      deserializedWrapper.eddsaPCD.pcd
    );
    return new EmailPCD(
      deserializedWrapper.id,
      {
        emailAddress: deserializedWrapper.emailAddress,
        semaphoreId: deserializedWrapper.semaphoreId
      },
      { eddsaPCD: deserializedEdDSAPCD }
    );
  });
}
function getDisplayOptions(pcd) {
  return {
    header: "Verified email",
    displayName: pcd.claim.emailAddress
  };
}
var EmailPCDPackage = {
  name: EmailPCDTypeName,
  renderCardBody: EmailCardBody,
  getDisplayOptions,
  prove,
  verify,
  serialize,
  deserialize
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EmailPCD,
  EmailPCDPackage,
  EmailPCDTypeName,
  deserialize,
  getDisplayOptions,
  getEmailAddress,
  getPublicKey,
  prove,
  serialize,
  verify
});
