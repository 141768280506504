"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  EthereumOwnershipPCD: () => EthereumOwnershipPCD,
  EthereumOwnershipPCDPackage: () => EthereumOwnershipPCDPackage,
  EthereumOwnershipPCDTypeName: () => EthereumOwnershipPCDTypeName,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  init: () => init,
  prove: () => prove,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/EthereumOwnershipPCD.ts
var import_pcd_types = require("@pcd/pcd-types");
var import_semaphore_identity_pcd = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd2 = require("@pcd/semaphore-signature-pcd");
var import_util = require("@pcd/util");
var import_ethers = require("ethers");
var import_json_bigint = __toESM(require("json-bigint"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_semaphore_signature_pcd = require("@pcd/semaphore-signature-pcd");
var import_react = require("react");
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function EthereumOwnershipCardBody({
  pcd
}) {
  const [identityCommitment, setIdentityCommitment] = (0, import_react.useState)("<deserializing>");
  (0, import_react.useEffect)(() => {
    import_semaphore_signature_pcd.SemaphoreSignaturePCDPackage.deserialize(pcd.proof.signatureProof.pcd).then(
      (pcd2) => {
        setIdentityCommitment(pcd2.claim.identityCommitment);
      }
    );
  }, [pcd]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("p", { children: "This PCD represents that a particular Semphore Identity owns a particular Ethereum Address." }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Separator, {}),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Commitment" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.HiddenText, { text: identityCommitment }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Spacer, { h: 8 }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Ethereum Address" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.TextContainer, { children: pcd.claim.ethereumAddress })
  ] });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;

// src/EthereumOwnershipPCD.ts
var EthereumOwnershipPCDTypeName = "ethereum-ownership-pcd";
var EthereumOwnershipPCD = class {
  constructor(id, claim, proof) {
    this.type = EthereumOwnershipPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function init(args) {
  return __async(this, null, function* () {
    var _a, _b;
    return (_b = (_a = import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage).init) == null ? void 0 : _b.call(_a, args);
  });
}
function prove(args) {
  return __async(this, null, function* () {
    if (args.identity.value === void 0) {
      throw new Error(`missing argument identity`);
    }
    if (args.ethereumSignatureOfCommitment.value === void 0) {
      throw new Error(`missing argument ethereumSignatureOfCommitment`);
    }
    if (args.ethereumAddress.value === void 0) {
      throw new Error(`missing argument ethereumAddress`);
    }
    if (!import_ethers.ethers.utils.isAddress(args.ethereumAddress.value)) {
      throw new Error(`${args.ethereumAddress} is not a valid Ethereum address`);
    }
    const deserializedIdentity = yield import_semaphore_identity_pcd.SemaphoreIdentityPCDPackage.deserialize(
      args.identity.value.pcd
    );
    const message = deserializedIdentity.claim.identity.commitment.toString();
    const address = import_ethers.ethers.utils.getAddress(
      import_ethers.ethers.utils.verifyMessage(
        message,
        args.ethereumSignatureOfCommitment.value
      )
    );
    const formattedArgAddress = import_ethers.ethers.utils.getAddress(
      args.ethereumAddress.value
    );
    if (address !== formattedArgAddress) {
      throw new Error(
        `recovered address ${address} does not match argument address: ${formattedArgAddress} `
      );
    }
    const semaphoreSignature = yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.prove({
      identity: {
        argumentType: import_pcd_types.ArgumentTypeName.PCD,
        pcdType: import_semaphore_identity_pcd.SemaphoreIdentityPCDTypeName,
        value: args.identity.value
      },
      signedMessage: {
        argumentType: import_pcd_types.ArgumentTypeName.String,
        value: args.ethereumSignatureOfCommitment.value
      }
    });
    return new EthereumOwnershipPCD(
      (0, import_uuid.v4)(),
      {
        ethereumAddress: args.ethereumAddress.value
      },
      {
        signatureProof: yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.serialize(semaphoreSignature),
        ethereumSignatureOfCommitment: args.ethereumSignatureOfCommitment.value
      }
    );
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    const semaphoreSignature = yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.deserialize(
      pcd.proof.signatureProof.pcd
    );
    const proofValid = yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.verify(semaphoreSignature);
    if (!proofValid) {
      return false;
    }
    if (semaphoreSignature.claim.signedMessage !== pcd.proof.ethereumSignatureOfCommitment) {
      return false;
    }
    const deserializedSignatureProof = yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.deserialize(
      pcd.proof.signatureProof.pcd
    );
    try {
      const recoveredAddress = import_ethers.ethers.utils.verifyMessage(
        deserializedSignatureProof.claim.identityCommitment,
        pcd.proof.ethereumSignatureOfCommitment
      );
      if (import_ethers.ethers.utils.getAddress(recoveredAddress) !== import_ethers.ethers.utils.getAddress(pcd.claim.ethereumAddress.toLowerCase())) {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
    return true;
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    return {
      type: EthereumOwnershipPCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify(pcd)
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const { id, claim, proof } = (0, import_json_bigint.default)().parse(serialized);
    (0, import_util.requireDefinedParameter)(id, "id");
    (0, import_util.requireDefinedParameter)(claim, "claim");
    (0, import_util.requireDefinedParameter)(proof, "proof");
    return new EthereumOwnershipPCD(id, claim, proof);
  });
}
function getDisplayOptions(pcd) {
  return {
    header: "Ethereum " + pcd.claim.ethereumAddress.substring(0, 12),
    displayName: "eth-owner-" + pcd.id.substring(0, 4)
  };
}
var EthereumOwnershipPCDPackage = {
  name: EthereumOwnershipPCDTypeName,
  renderCardBody: EthereumOwnershipCardBody,
  getDisplayOptions,
  init,
  prove,
  verify,
  serialize,
  deserialize
};
