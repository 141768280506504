"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  STATIC_SIGNATURE_PCD_NULLIFIER: () => STATIC_SIGNATURE_PCD_NULLIFIER,
  SemaphoreSignaturePCD: () => SemaphoreSignaturePCD,
  SemaphoreSignaturePCDPackage: () => SemaphoreSignaturePCDPackage,
  SemaphoreSignaturePCDTypeName: () => SemaphoreSignaturePCDTypeName,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  init: () => init,
  prove: () => prove,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/SemaphoreSignaturePCD.ts
var import_semaphore_identity_pcd = require("@pcd/semaphore-identity-pcd");
var import_util = require("@pcd/util");
var import_group = require("@semaphore-protocol/group");
var import_proof = require("@semaphore-protocol/proof");
var import_json_bigint = __toESM(require("json-bigint"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function SemaphoreIdentityCardBody({
  pcd
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("p", { children: "This PCD represents a particular message that has been signed by a particular Semaphore Identity." }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Separator, {}),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Commitment" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.HiddenText, { text: pcd.claim.identityCommitment }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Spacer, { h: 8 }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Signed Message" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.TextContainer, { children: pcd.claim.signedMessage })
  ] });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;

// src/SemaphoreSignaturePCD.ts
var STATIC_SIGNATURE_PCD_NULLIFIER = (0, import_util.generateSnarkMessageHash)(
  "hardcoded-nullifier"
);
var SemaphoreSignaturePCDTypeName = "semaphore-signature-pcd";
var initArgs = void 0;
var SemaphoreSignaturePCD = class {
  constructor(id, claim, proof) {
    this.type = SemaphoreSignaturePCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function init(args) {
  return __async(this, null, function* () {
    initArgs = args;
  });
}
function prove(args) {
  return __async(this, null, function* () {
    var _a;
    if (!initArgs) {
      throw new Error(
        "cannot make semaphore signature proof: init has not been called yet"
      );
    }
    const serializedIdentityPCD = (_a = args.identity.value) == null ? void 0 : _a.pcd;
    if (!serializedIdentityPCD) {
      throw new Error(
        "cannot make semaphore signature proof: identity is not set"
      );
    }
    const identityPCD = yield import_semaphore_identity_pcd.SemaphoreIdentityPCDPackage.deserialize(
      serializedIdentityPCD
    );
    if (args.signedMessage.value === void 0) {
      throw new Error(
        "cannot make semaphore signature proof: signed message is not set"
      );
    }
    const group = new import_group.Group(1, 16);
    group.addMember(identityPCD.claim.identity.commitment);
    const signal = (0, import_util.generateSnarkMessageHash)(args.signedMessage.value);
    const fullProof = yield (0, import_proof.generateProof)(
      identityPCD.claim.identity,
      group,
      STATIC_SIGNATURE_PCD_NULLIFIER,
      signal,
      {
        zkeyFilePath: initArgs.zkeyFilePath,
        wasmFilePath: initArgs.wasmFilePath
      }
    );
    const claim = {
      identityCommitment: identityPCD.claim.identity.commitment.toString(),
      signedMessage: args.signedMessage.value,
      nullifierHash: fullProof.nullifierHash + ""
    };
    const proof = fullProof.proof;
    return new SemaphoreSignaturePCD((0, import_uuid.v4)(), claim, proof);
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    const group = new import_group.Group(1, 16);
    group.addMember(pcd.claim.identityCommitment);
    const fullProof = {
      externalNullifier: STATIC_SIGNATURE_PCD_NULLIFIER.toString(),
      merkleTreeRoot: group.root + "",
      nullifierHash: pcd.claim.nullifierHash,
      proof: pcd.proof,
      signal: (0, import_util.generateSnarkMessageHash)(pcd.claim.signedMessage).toString()
    };
    const validProof = yield (0, import_proof.verifyProof)(fullProof, 16);
    return validProof;
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    return {
      type: SemaphoreSignaturePCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify(pcd)
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const { id, claim, proof } = (0, import_json_bigint.default)().parse(serialized);
    (0, import_util.requireDefinedParameter)(id, "id");
    (0, import_util.requireDefinedParameter)(claim, "claim");
    (0, import_util.requireDefinedParameter)(proof, "proof");
    return new SemaphoreSignaturePCD(id, claim, proof);
  });
}
function getDisplayOptions(pcd) {
  return {
    header: "Semaphore Signature",
    displayName: "semaphore-sig-" + pcd.id.substring(0, 4)
  };
}
var SemaphoreSignaturePCDPackage = {
  name: SemaphoreSignaturePCDTypeName,
  renderCardBody: SemaphoreIdentityCardBody,
  getDisplayOptions,
  init,
  prove,
  verify,
  serialize,
  deserialize
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  STATIC_SIGNATURE_PCD_NULLIFIER,
  SemaphoreSignaturePCD,
  SemaphoreSignaturePCDPackage,
  SemaphoreSignaturePCDTypeName,
  deserialize,
  getDisplayOptions,
  init,
  prove,
  serialize,
  verify
});
