"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  SemaphoreIdentityPCD: () => SemaphoreIdentityPCD,
  SemaphoreIdentityPCDPackage: () => SemaphoreIdentityPCDPackage,
  SemaphoreIdentityPCDTypeName: () => SemaphoreIdentityPCDTypeName,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  prove: () => prove,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/SemaphoreIdentityPCD.ts
var import_util = require("@pcd/util");
var import_identity = require("@semaphore-protocol/identity");
var import_json_bigint = __toESM(require("json-bigint"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function SemaphoreIdentityCardBody({
  pcd
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("p", { children: "This PCD represents a Sempahore Identity, which can be used to send anonymous group signals, log into websites, etc." }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Separator, {}),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Semaphore Commitment" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.HiddenText, { text: pcd.claim.identity.commitment.toString() })
  ] });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;

// src/SemaphoreIdentityPCD.ts
var SemaphoreIdentityPCDTypeName = "semaphore-identity-pcd";
var SemaphoreIdentityPCD = class {
  constructor(id, claim) {
    this.type = SemaphoreIdentityPCDTypeName;
    this.claim = claim;
    this.proof = void 0;
    this.id = id;
  }
};
function prove(args) {
  return __async(this, null, function* () {
    return new SemaphoreIdentityPCD((0, import_uuid.v4)(), { identity: args.identity });
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    var _a;
    return ((_a = pcd == null ? void 0 : pcd.claim) == null ? void 0 : _a.identity) !== void 0;
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    return {
      type: SemaphoreIdentityPCDTypeName,
      pcd: import_json_bigint.default.stringify({
        type: pcd.type,
        id: pcd.id,
        identity: pcd.claim.identity.toString()
      })
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const { id, identity } = import_json_bigint.default.parse(serialized);
    (0, import_util.requireDefinedParameter)(id, "id");
    (0, import_util.requireDefinedParameter)(identity, "identity");
    return new SemaphoreIdentityPCD(id, {
      identity: new import_identity.Identity(identity)
    });
  });
}
function getDisplayOptions(pcd) {
  return {
    header: "Semaphore Identity",
    displayName: "semaphore-id-" + pcd.claim.identity.commitment.toString().substring(0, 8)
  };
}
var SemaphoreIdentityPCDPackage = {
  name: SemaphoreIdentityPCDTypeName,
  renderCardBody: SemaphoreIdentityCardBody,
  getDisplayOptions,
  prove,
  verify,
  serialize,
  deserialize
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  SemaphoreIdentityPCD,
  SemaphoreIdentityPCDPackage,
  SemaphoreIdentityPCDTypeName,
  deserialize,
  getDisplayOptions,
  prove,
  serialize,
  verify
});
