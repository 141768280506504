"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  EdDSAPCD: () => EdDSAPCD,
  EdDSAPCDPackage: () => EdDSAPCDPackage,
  EdDSAPCDTypeName: () => EdDSAPCDTypeName,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  getEdDSAPublicKey: () => getEdDSAPublicKey,
  isEqualEdDSAPublicKey: () => isEqualEdDSAPublicKey,
  newEdDSAPrivateKey: () => newEdDSAPrivateKey,
  prove: () => prove,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/EdDSAPCD.ts
var import_util = require("@pcd/util");
var import_circomlibjs = require("circomlibjs");
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function EdDSACardBody({ pcd }) {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("p", { children: "This PCD represents an EdDSA signature of some bigint values" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Separator, {}),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Signed Message" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      import_passport_ui.HiddenText,
      {
        text: pcd.claim.message.map((num) => num.toString()).join(",")
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Spacer, { h: 8 }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "EdDSA Public Key" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.HiddenText, { text: pcd.claim.publicKey.join(",") })
  ] });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;

// src/EdDSAPCD.ts
var EdDSAPCDTypeName = "eddsa-pcd";
var EdDSAPCD = class {
  constructor(id, claim, proof) {
    this.type = EdDSAPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
var initializedPromise;
var eddsa;
function ensureInitialized() {
  return __async(this, null, function* () {
    if (!initializedPromise) {
      initializedPromise = (() => __async(this, null, function* () {
        eddsa = yield (0, import_circomlibjs.buildEddsa)();
      }))();
    }
    yield initializedPromise;
  });
}
function prove(args) {
  return __async(this, null, function* () {
    yield ensureInitialized();
    let message;
    if (!args.privateKey.value)
      throw new Error("No private key value provided");
    if (!args.message.value)
      throw new Error("No message value provided");
    try {
      message = args.message.value.map((fieldStr) => BigInt(fieldStr));
    } catch (e) {
      throw new Error("Could not convert message contents to bigint type");
    }
    const id = typeof args.id.value === "string" ? args.id.value : (0, import_uuid.v4)();
    const prvKey = (0, import_util.fromHexString)(args.privateKey.value);
    const hashedMessage = eddsa.poseidon(message);
    const publicKey = yield getEdDSAPublicKey(prvKey);
    const signature = (0, import_util.toHexString)(
      eddsa.packSignature(eddsa.signPoseidon(prvKey, hashedMessage))
    );
    return new EdDSAPCD(id, { message, publicKey }, { signature });
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    try {
      yield ensureInitialized();
      const signature = eddsa.unpackSignature((0, import_util.fromHexString)(pcd.proof.signature));
      const pubKey = pcd.claim.publicKey.map(
        (p) => eddsa.F.fromObject(p)
      );
      const hashedMessage = eddsa.poseidon(pcd.claim.message);
      return eddsa.verifyPoseidon(hashedMessage, signature, pubKey);
    } catch (e) {
      return false;
    }
  });
}
function replacer(key, value) {
  if (key === "message") {
    return value.map((num) => num.toString(16));
  } else {
    return value;
  }
}
function reviver(key, value) {
  if (key === "message") {
    return value.map((str) => BigInt(`0x${str}`));
  } else {
    return value;
  }
}
function serialize(pcd) {
  return __async(this, null, function* () {
    return {
      type: EdDSAPCDTypeName,
      pcd: JSON.stringify(pcd, replacer)
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const { id, claim, proof } = JSON.parse(serialized, reviver);
    (0, import_util.requireDefinedParameter)(id, "id");
    (0, import_util.requireDefinedParameter)(claim, "claim");
    (0, import_util.requireDefinedParameter)(proof, "proof");
    return new EdDSAPCD(id, claim, proof);
  });
}
function getDisplayOptions(pcd) {
  return {
    header: "EdDSA Signature",
    displayName: "eddsa-sig-" + pcd.id.substring(0, 4)
  };
}
var EdDSAPCDPackage = {
  name: EdDSAPCDTypeName,
  renderCardBody: EdDSACardBody,
  getDisplayOptions,
  prove,
  verify,
  serialize,
  deserialize
};
function getEdDSAPublicKey(privateKey) {
  return __async(this, null, function* () {
    yield ensureInitialized();
    if (typeof privateKey === "string") {
      privateKey = (0, import_util.fromHexString)(privateKey);
    }
    return eddsa.prv2pub(privateKey).map(
      (p) => (
        // `F.toObject` converts a point from Montgomery format to a standard one.
        eddsa.F.toObject(p).toString(16).padStart(64, "0")
      )
    );
  });
}

// src/util/util.ts
var import_util2 = require("@pcd/util");
function newEdDSAPrivateKey() {
  return (0, import_util2.toHexString)((0, import_util2.getRandomValues)(32));
}
function isEqualEdDSAPublicKey(a, b) {
  return a[0] === b[0] && a[1] === b[1];
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EdDSAPCD,
  EdDSAPCDPackage,
  EdDSAPCDTypeName,
  deserialize,
  getDisplayOptions,
  getEdDSAPublicKey,
  isEqualEdDSAPublicKey,
  newEdDSAPrivateKey,
  prove,
  serialize,
  verify
});
