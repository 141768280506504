"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  SemaphoreGroupPCD: () => SemaphoreGroupPCD,
  SemaphoreGroupPCDPackage: () => SemaphoreGroupPCDPackage,
  SemaphoreGroupPCDTypeName: () => SemaphoreGroupPCDTypeName,
  deserialize: () => deserialize,
  deserializeSemaphoreGroup: () => deserializeSemaphoreGroup,
  getDisplayOptions: () => getDisplayOptions,
  init: () => init,
  prove: () => prove,
  serialize: () => serialize,
  serializeSemaphoreGroup: () => serializeSemaphoreGroup,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/SemaphoreGroupPCD.ts
var import_semaphore_identity_pcd = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd = require("@pcd/semaphore-signature-pcd");
var import_util = require("@pcd/util");
var import_proof = require("@semaphore-protocol/proof");
var import_json_bigint = __toESM(require("json-bigint"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function SemaphoreGroupCardBody({ pcd }) {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("p", { children: "This PCD represents a group signal in the context of a Semaphore Protocol group. In other words, this is a ZK proof that a particular external nullifier sent a particular signal and belongs to a particular merkle tree identified by the merkle root." }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Separator, {}),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Group Root" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.TextContainer, { children: pcd.claim.merkleRoot }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Spacer, { h: 8 }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Signal" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.TextContainer, { children: pcd.claim.signal }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Spacer, { h: 8 }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "Nullifier Hash" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.TextContainer, { children: pcd.claim.nullifierHash }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.Spacer, { h: 8 }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.FieldLabel, { children: "External Nullifier" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_passport_ui.TextContainer, { children: pcd.claim.externalNullifier })
  ] });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;

// src/SerializedSemaphoreGroup.ts
var import_group = require("@semaphore-protocol/group");
function serializeSemaphoreGroup(group, name) {
  return {
    id: group.id.toString(),
    name,
    members: group.members.map((m) => m.toString()),
    depth: group.depth,
    zeroValue: group.zeroValue.toString()
  };
}
function deserializeSemaphoreGroup(serializedGroup) {
  const group = new import_group.Group(
    BigInt(serializedGroup.id),
    serializedGroup.depth,
    serializedGroup.members.map((m) => BigInt(m))
  );
  return group;
}

// src/SemaphoreGroupPCD.ts
var initArgs = void 0;
var SemaphoreGroupPCDTypeName = "semaphore-group-signal";
var SemaphoreGroupPCD = class {
  constructor(id, claim, proof) {
    this.type = SemaphoreGroupPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function init(args) {
  return __async(this, null, function* () {
    initArgs = args;
  });
}
function prove(args) {
  return __async(this, null, function* () {
    var _a;
    if (!initArgs) {
      throw new Error("Cannot make group proof: init has not been called yet");
    }
    const serializedIdentityPCD = (_a = args.identity.value) == null ? void 0 : _a.pcd;
    if (!serializedIdentityPCD) {
      throw new Error("Cannot make group proof: missing semaphore identity PCD");
    }
    const identityPCD = yield import_semaphore_identity_pcd.SemaphoreIdentityPCDPackage.deserialize(
      serializedIdentityPCD
    );
    const serializedGroup = args.group.value;
    if (!serializedGroup) {
      throw new Error("Cannot make group proof: missing semaphore group");
    }
    if (!args.externalNullifier.value) {
      throw new Error("Cannot make group proof: missing externalNullifier");
    }
    if (!args.signal.value) {
      throw new Error("Cannot make group proof: missing signal");
    }
    if (BigInt(args.externalNullifier.value) === import_semaphore_signature_pcd.STATIC_SIGNATURE_PCD_NULLIFIER) {
      throw new Error(
        "Cannot make group proof: same externalNullifier as SemaphoreSignaturePCD, which would break anonymity"
      );
    }
    const deserializedGroup = deserializeSemaphoreGroup(serializedGroup);
    const fullProof = yield (0, import_proof.generateProof)(
      identityPCD.claim.identity,
      deserializedGroup,
      args.externalNullifier.value,
      args.signal.value,
      {
        zkeyFilePath: initArgs.zkeyFilePath,
        wasmFilePath: initArgs.wasmFilePath
      }
    );
    const claim = {
      merkleRoot: deserializedGroup.root.toString(),
      depth: deserializedGroup.depth,
      externalNullifier: args.externalNullifier.value.toString(),
      nullifierHash: fullProof.nullifierHash.toString(),
      signal: args.signal.value.toString()
    };
    const proof = fullProof.proof;
    return new SemaphoreGroupPCD((0, import_uuid.v4)(), claim, proof);
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    const fullProof = {
      externalNullifier: pcd.claim.externalNullifier,
      merkleTreeRoot: pcd.claim.merkleRoot + "",
      nullifierHash: pcd.claim.nullifierHash,
      signal: pcd.claim.signal,
      proof: pcd.proof
    };
    const valid = yield (0, import_proof.verifyProof)(fullProof, pcd.claim.depth);
    return valid;
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    return {
      type: SemaphoreGroupPCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify(pcd)
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const { id, claim, proof } = (0, import_json_bigint.default)().parse(serialized);
    (0, import_util.requireDefinedParameter)(id, "id");
    (0, import_util.requireDefinedParameter)(claim, "claim");
    (0, import_util.requireDefinedParameter)(proof, "proof");
    return new SemaphoreGroupPCD(id, claim, proof);
  });
}
function getDisplayOptions(pcd) {
  return {
    header: "Semaphore Group Signal",
    displayName: "semaphore-group-" + pcd.id.substring(0, 4)
  };
}
var SemaphoreGroupPCDPackage = {
  name: SemaphoreGroupPCDTypeName,
  getDisplayOptions,
  renderCardBody: SemaphoreGroupCardBody,
  init,
  prove,
  verify,
  serialize,
  deserialize
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  SemaphoreGroupPCD,
  SemaphoreGroupPCDPackage,
  SemaphoreGroupPCDTypeName,
  deserialize,
  deserializeSemaphoreGroup,
  getDisplayOptions,
  init,
  prove,
  serialize,
  serializeSemaphoreGroup,
  verify
});
