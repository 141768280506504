"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  EdDSAPCDTypeName: () => EdDSAPCDTypeName,
  EdDSATicketPCD: () => EdDSATicketPCD2,
  EdDSATicketPCDPackage: () => EdDSATicketPCDPackage,
  TicketCategory: () => TicketCategory,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  getEdDSATicketData: () => getEdDSATicketData,
  getPublicKey: () => getPublicKey,
  getQRCodeColorOverride: () => getQRCodeColorOverride,
  initArgs: () => initArgs,
  isEdDSATicketPCD: () => isEdDSATicketPCD,
  prove: () => prove,
  semaphoreIdToBigInt: () => semaphoreIdToBigInt,
  serialize: () => serialize,
  ticketDataToBigInts: () => ticketDataToBigInts,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/EdDSATicketPCD.ts
var import_eddsa_pcd = require("@pcd/eddsa-pcd");
var import_pcd_types = require("@pcd/pcd-types");
var import_json_bigint = __toESM(require("json-bigint"));
var import_lodash = __toESM(require("lodash"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_passport_ui = require("@pcd/passport-ui");
var import_react = require("react");
var import_styled_components = __toESM(require("styled-components"));

// src/utils.ts
var import_util = require("@pcd/util");
function semaphoreIdToBigInt(v) {
  return BigInt(v);
}
function ticketDataToBigInts(data) {
  return [
    (0, import_util.uuidToBigInt)(data.ticketId),
    (0, import_util.uuidToBigInt)(data.eventId),
    (0, import_util.uuidToBigInt)(data.productId),
    (0, import_util.numberToBigInt)(data.timestampConsumed),
    (0, import_util.numberToBigInt)(data.timestampSigned),
    semaphoreIdToBigInt(data.attendeeSemaphoreId),
    (0, import_util.booleanToBigInt)(data.isConsumed),
    (0, import_util.booleanToBigInt)(data.isRevoked),
    (0, import_util.numberToBigInt)(data.ticketCategory),
    (0, import_util.generateSnarkMessageHash)(data.attendeeEmail),
    (0, import_util.generateSnarkMessageHash)(data.attendeeName),
    (0, import_util.numberToBigInt)(0)
  ];
}
function getEdDSATicketData(pcd) {
  var _a;
  return (_a = pcd == null ? void 0 : pcd.claim) == null ? void 0 : _a.ticket;
}
function getPublicKey(pcd) {
  var _a, _b, _c;
  return (_c = (_b = (_a = pcd == null ? void 0 : pcd.proof) == null ? void 0 : _a.eddsaPCD) == null ? void 0 : _b.claim) == null ? void 0 : _c.publicKey;
}
var INVALID_TICKET_QR_CODE_COLOR = "#d3d3d3";
function getQRCodeColorOverride(pcd) {
  const ticketData = getEdDSATicketData(pcd);
  if (!ticketData || ticketData.isRevoked) {
    return INVALID_TICKET_QR_CODE_COLOR;
  }
  return void 0;
}

// src/CardBody.tsx
var import_jsx_runtime = require("react/jsx-runtime");
function EdDSATicketCardBody({ pcd }) {
  const ticketData = getEdDSATicketData(pcd);
  const showImage = (ticketData == null ? void 0 : ticketData.ticketCategory) === 4 /* Generic */ || (ticketData == null ? void 0 : ticketData.ticketCategory) === 3 /* Zuzalu */;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Container, { padding: !showImage, children: [
    showImage && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(TicketImage, { pcd }),
    !showImage && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(TicketQR, { pcd }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(TicketInfo, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("span", { children: ticketData == null ? void 0 : ticketData.attendeeName }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)("span", { children: ticketData == null ? void 0 : ticketData.attendeeEmail })
    ] })
  ] });
}
function TicketImage({ pcd }) {
  const { imageUrl, imageAltText } = pcd.claim.ticket;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)("img", { src: imageUrl, alt: imageAltText });
}
function TicketQR({ pcd }) {
  const generate = (0, import_react.useCallback)(() => __async(this, null, function* () {
    const serialized = yield EdDSATicketPCDPackage.serialize(pcd);
    const serializedPCD = JSON.stringify(serialized);
    const encodedPCD = (0, import_passport_ui.encodeQRPayload)(serializedPCD);
    if (!initArgs.makeEncodedVerifyLink) {
      throw new Error("must provide makeEncodedVerifyLink");
    }
    const verificationLink = initArgs.makeEncodedVerifyLink(encodedPCD);
    return verificationLink;
  }), [pcd]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    import_passport_ui.QRDisplayWithRegenerateAndStorage,
    {
      generateQRPayload: generate,
      maxAgeMs: 1e3 * 60,
      uniqueId: pcd.id,
      fgColor: getQRCodeColorOverride(pcd)
    }
  );
}
var Container = import_styled_components.default.span`
  ${({ padding }) => padding ? import_styled_components.css`
          padding: 16px;
        ` : import_styled_components.css``}
  overflow: hidden;
  width: 100%;
`;
var TicketInfo = import_styled_components.default.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// src/EdDSATicketPCD.ts
var EdDSAPCDTypeName = "eddsa-ticket-pcd";
var TicketCategory = /* @__PURE__ */ ((TicketCategory2) => {
  TicketCategory2[TicketCategory2["ZuConnect"] = 0] = "ZuConnect";
  TicketCategory2[TicketCategory2["Devconnect"] = 1] = "Devconnect";
  TicketCategory2[TicketCategory2["PcdWorkingGroup"] = 2] = "PcdWorkingGroup";
  TicketCategory2[TicketCategory2["Zuzalu"] = 3] = "Zuzalu";
  TicketCategory2[TicketCategory2["Generic"] = 4] = "Generic";
  return TicketCategory2;
})(TicketCategory || {});
var initArgs;
function init(args) {
  return __async(this, null, function* () {
    initArgs = args;
  });
}
var EdDSATicketPCD2 = class {
  constructor(id, claim, proof) {
    this.type = EdDSAPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function prove(args) {
  return __async(this, null, function* () {
    var _a;
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    if (!args.privateKey.value) {
      throw new Error("missing private key");
    }
    if (!args.ticket.value) {
      throw new Error("missing ticket value");
    }
    const serializedTicket = ticketDataToBigInts(args.ticket.value);
    const eddsaPCD = yield import_eddsa_pcd.EdDSAPCDPackage.prove({
      message: {
        value: serializedTicket.map((b) => b.toString()),
        argumentType: import_pcd_types.ArgumentTypeName.StringArray
      },
      privateKey: {
        value: args.privateKey.value,
        argumentType: import_pcd_types.ArgumentTypeName.String
      },
      id: {
        value: void 0,
        argumentType: import_pcd_types.ArgumentTypeName.String
      }
    });
    const id = (_a = args.id.value) != null ? _a : (0, import_uuid.v4)();
    return new EdDSATicketPCD2(id, { ticket: args.ticket.value }, { eddsaPCD });
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    const messageDerivedFromClaim = ticketDataToBigInts(pcd.claim.ticket);
    if (!import_lodash.default.isEqual(messageDerivedFromClaim, pcd.proof.eddsaPCD.claim.message)) {
      return false;
    }
    return import_eddsa_pcd.EdDSAPCDPackage.verify(pcd.proof.eddsaPCD);
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    const serializedEdDSAPCD = yield import_eddsa_pcd.EdDSAPCDPackage.serialize(
      pcd.proof.eddsaPCD
    );
    return {
      type: EdDSAPCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify({
        id: pcd.id,
        eddsaPCD: serializedEdDSAPCD,
        ticket: pcd.claim.ticket
      })
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    if (!initArgs) {
      throw new Error("package not initialized");
    }
    const deserializedWrapper = (0, import_json_bigint.default)().parse(serialized);
    const deserializedEdDSAPCD = yield import_eddsa_pcd.EdDSAPCDPackage.deserialize(
      deserializedWrapper.eddsaPCD.pcd
    );
    return new EdDSATicketPCD2(
      deserializedWrapper.id,
      { ticket: deserializedWrapper.ticket },
      { eddsaPCD: deserializedEdDSAPCD }
    );
  });
}
function getDisplayOptions(pcd) {
  if (!initArgs) {
    throw new Error("package not initialized");
  }
  const ticketData = getEdDSATicketData(pcd);
  if (!ticketData) {
    return {
      header: "Ticket",
      displayName: "ticket-" + pcd.id.substring(0, 4)
    };
  }
  let header = "Ticket";
  if (ticketData.isRevoked) {
    header = `[CANCELED] ${ticketData.eventName} (${ticketData.ticketName})`;
  } else if (ticketData.isConsumed) {
    header = `[SCANNED] ${ticketData.eventName} (${ticketData.ticketName})`;
  } else if (ticketData.eventName && ticketData.ticketName) {
    header = `${ticketData.eventName} (${ticketData.ticketName})`;
  }
  return {
    header,
    displayName: `${ticketData.eventName} (${ticketData.ticketName})`
  };
}
function isEdDSATicketPCD(pcd) {
  return pcd.type === EdDSAPCDTypeName;
}
var EdDSATicketPCDPackage = {
  name: EdDSAPCDTypeName,
  renderCardBody: EdDSATicketCardBody,
  getDisplayOptions,
  init,
  prove,
  verify,
  serialize,
  deserialize
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EdDSAPCDTypeName,
  EdDSATicketPCD,
  EdDSATicketPCDPackage,
  TicketCategory,
  deserialize,
  getDisplayOptions,
  getEdDSATicketData,
  getPublicKey,
  getQRCodeColorOverride,
  initArgs,
  isEdDSATicketPCD,
  prove,
  semaphoreIdToBigInt,
  serialize,
  ticketDataToBigInts,
  verify
});
