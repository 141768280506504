"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  CredentialManager: () => CredentialManager,
  ERROR_NAME_BAD_RESPONSE: () => ERROR_NAME_BAD_RESPONSE,
  ERROR_NAME_UNKNOWN: () => ERROR_NAME_UNKNOWN,
  FROG_FREEROLLS: () => FROG_FREEROLLS,
  FROG_SCORE_CAP: () => FROG_SCORE_CAP,
  FeedHost: () => FeedHost,
  FeedSubscriptionManager: () => FeedSubscriptionManager,
  FrogCryptoDbFeedDataSchema: () => FrogCryptoDbFeedDataSchema,
  FrogCryptoFeedBiomeConfigSchema: () => FrogCryptoFeedBiomeConfigSchema,
  FrogCryptoFeedBiomeConfigsSchema: () => FrogCryptoFeedBiomeConfigsSchema,
  FrogCryptoFolderName: () => FrogCryptoFolderName,
  FrogCryptoFrogDataSchema: () => FrogCryptoFrogDataSchema,
  IFrogCryptoClientFeedSchema: () => IFrogCryptoClientFeedSchema,
  IFrogCryptoFeedSchema: () => IFrogCryptoFeedSchema,
  ISSUANCE_STRING: () => ISSUANCE_STRING,
  KnownPublicKeyType: () => KnownPublicKeyType,
  KnownTicketGroup: () => KnownTicketGroup,
  LATEST_PRIVACY_NOTICE: () => LATEST_PRIVACY_NOTICE,
  MIN_PRIVACY_NOTICE: () => MIN_PRIVACY_NOTICE,
  NetworkFeedApi: () => NetworkFeedApi,
  PCDRequestType: () => PCDRequestType,
  POST: () => POST,
  PayloadType: () => PayloadType,
  PendingPCDStatus: () => PendingPCDStatus,
  SubscriptionErrorType: () => SubscriptionErrorType,
  UNREDACT_TICKETS_TERMS_VERSION: () => UNREDACT_TICKETS_TERMS_VERSION,
  ZUCONNECT_23_DAY_PASS_EVENT_ID: () => ZUCONNECT_23_DAY_PASS_EVENT_ID,
  ZUCONNECT_23_DAY_PASS_PRODUCT_ID: () => ZUCONNECT_23_DAY_PASS_PRODUCT_ID,
  ZUCONNECT_23_FIRST_WEEK_EVENT_ID: () => ZUCONNECT_23_FIRST_WEEK_EVENT_ID,
  ZUCONNECT_23_FIRST_WEEK_PRODUCT_ID: () => ZUCONNECT_23_FIRST_WEEK_PRODUCT_ID,
  ZUCONNECT_23_ORGANIZER_EVENT_ID: () => ZUCONNECT_23_ORGANIZER_EVENT_ID,
  ZUCONNECT_23_ORGANIZER_PRODUCT_ID: () => ZUCONNECT_23_ORGANIZER_PRODUCT_ID,
  ZUCONNECT_23_RESIDENT_EVENT_ID: () => ZUCONNECT_23_RESIDENT_EVENT_ID,
  ZUCONNECT_23_RESIDENT_PRODUCT_ID: () => ZUCONNECT_23_RESIDENT_PRODUCT_ID,
  ZUCONNECT_23_SCHOLARSHIP_EVENT_ID: () => ZUCONNECT_23_SCHOLARSHIP_EVENT_ID,
  ZUCONNECT_23_SCHOLARSHIP_PRODUCT_ID: () => ZUCONNECT_23_SCHOLARSHIP_PRODUCT_ID,
  ZUCONNECT_PRODUCT_ID_MAPPINGS: () => ZUCONNECT_PRODUCT_ID_MAPPINGS,
  ZUCONNECT_TICKET_NAMES: () => ZUCONNECT_TICKET_NAMES,
  ZUZALU_23_EVENT_ID: () => ZUZALU_23_EVENT_ID,
  ZUZALU_23_ORGANIZER_PRODUCT_ID: () => ZUZALU_23_ORGANIZER_PRODUCT_ID,
  ZUZALU_23_RESIDENT_PRODUCT_ID: () => ZUZALU_23_RESIDENT_PRODUCT_ID,
  ZUZALU_23_VISITOR_PRODUCT_ID: () => ZUZALU_23_VISITOR_PRODUCT_ID,
  ZupassFeedIds: () => ZupassFeedIds,
  ZuzaluUserRole: () => ZuzaluUserRole,
  agreeTerms: () => agreeTerms,
  apiErrorReasonFromCode: () => apiErrorReasonFromCode,
  applyActions: () => applyActions,
  checkTicketById: () => checkTicketById,
  checkinTicketById: () => checkinTicketById,
  constructZupassPcdAddRequestUrl: () => constructZupassPcdAddRequestUrl,
  constructZupassPcdGetRequestUrl: () => constructZupassPcdGetRequestUrl,
  constructZupassPcdProveAndAddRequestUrl: () => constructZupassPcdProveAndAddRequestUrl,
  createCredentialCache: () => createCredentialCache,
  createFeedCredentialPayload: () => createFeedCredentialPayload,
  createStorageBackedCredentialCache: () => createStorageBackedCredentialCache,
  defaultOfflineTickets: () => defaultOfflineTickets,
  deserializeStorage: () => deserializeStorage,
  frogCryptoGetUserState: () => frogCryptoGetUserState,
  frogCryptoUpdateTelegramHandleSharing: () => frogCryptoUpdateTelegramHandleSharing,
  getNamedAPIErrorMessage: () => getNamedAPIErrorMessage,
  getStorageHash: () => getStorageHash,
  getWithoutProvingUrl: () => getWithoutProvingUrl,
  hashProveRequest: () => hashProveRequest,
  httpGet: () => httpGet,
  httpGetSimple: () => httpGetSimple,
  httpPost: () => httpPost,
  httpPostSimple: () => httpPostSimple,
  isSettledPendingPCDStatus: () => isSettledPendingPCDStatus,
  isSyncedEncryptedStorageV1: () => isSyncedEncryptedStorageV1,
  isSyncedEncryptedStorageV2: () => isSyncedEncryptedStorageV2,
  isSyncedEncryptedStorageV3: () => isSyncedEncryptedStorageV3,
  mapStringError: () => mapStringError,
  offlineTickets: () => offlineTickets,
  offlineTicketsCheckin: () => offlineTicketsCheckin,
  onNamedAPIError: () => onNamedAPIError,
  openGroupMembershipPopup: () => openGroupMembershipPopup,
  openSemaphoreSignaturePopup: () => openSemaphoreSignaturePopup,
  openSignedZuzaluSignInPopup: () => openSignedZuzaluSignInPopup,
  openSignedZuzaluUUIDPopup: () => openSignedZuzaluUUIDPopup,
  openZupassPopup: () => openZupassPopup,
  pollFeed: () => pollFeed,
  requestAgreeTerms: () => requestAgreeTerms,
  requestChangeBlobKey: () => requestChangeBlobKey,
  requestCheckInById: () => requestCheckInById,
  requestCheckTicketById: () => requestCheckTicketById,
  requestConfirmationEmail: () => requestConfirmationEmail,
  requestCreateNewUser: () => requestCreateNewUser,
  requestDownloadAndDecryptStorage: () => requestDownloadAndDecryptStorage,
  requestDownloadAndDecryptUpdatedStorage: () => requestDownloadAndDecryptUpdatedStorage,
  requestEncryptedStorage: () => requestEncryptedStorage,
  requestFrogCryptoDeleteFrogs: () => requestFrogCryptoDeleteFrogs,
  requestFrogCryptoGetScoreboard: () => requestFrogCryptoGetScoreboard,
  requestFrogCryptoGetUserState: () => requestFrogCryptoGetUserState,
  requestFrogCryptoUpdateFeeds: () => requestFrogCryptoUpdateFeeds,
  requestFrogCryptoUpdateFrogs: () => requestFrogCryptoUpdateFrogs,
  requestFrogCryptoUpdateTelegramHandleSharing: () => requestFrogCryptoUpdateTelegramHandleSharing,
  requestIssuanceServiceEnabled: () => requestIssuanceServiceEnabled,
  requestKnownTicketTypes: () => requestKnownTicketTypes,
  requestListFeeds: () => requestListFeeds,
  requestLogToServer: () => requestLogToServer,
  requestOfflineTickets: () => requestOfflineTickets,
  requestOfflineTicketsCheckin: () => requestOfflineTicketsCheckin,
  requestPasswordSalt: () => requestPasswordSalt,
  requestPollFeed: () => requestPollFeed,
  requestPretixSyncStatus: () => requestPretixSyncStatus,
  requestProveOnServer: () => requestProveOnServer,
  requestSemaphoreGroup: () => requestSemaphoreGroup,
  requestServerEdDSAPublicKey: () => requestServerEdDSAPublicKey,
  requestServerProofStatus: () => requestServerProofStatus,
  requestServerRSAPublicKey: () => requestServerRSAPublicKey,
  requestUploadEncryptedStorage: () => requestUploadEncryptedStorage,
  requestUser: () => requestUser,
  requestVerifyTicket: () => requestVerifyTicket,
  requestVerifyTicketById: () => requestVerifyTicketById,
  requestVerifyToken: () => requestVerifyToken,
  serializeStorage: () => serializeStorage,
  useFetchUser: () => useFetchUser,
  usePCDMultiplexer: () => usePCDMultiplexer,
  usePendingPCD: () => usePendingPCD,
  useSemaphoreGroupProof: () => useSemaphoreGroupProof,
  useSemaphoreSignatureProof: () => useSemaphoreSignatureProof,
  useSerializedPCD: () => useSerializedPCD,
  useZupassPopupMessages: () => useZupassPopupMessages,
  useZupassPopupSetup: () => useZupassPopupSetup,
  verifyFeedCredential: () => verifyFeedCredential,
  zupassDefaultSubscriptions: () => zupassDefaultSubscriptions
});
module.exports = __toCommonJS(src_exports);

// src/CredentialManager.ts
var import_pcd_types = require("@pcd/pcd-types");
var import_semaphore_identity_pcd = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd2 = require("@pcd/semaphore-signature-pcd");
var import_util2 = require("@pcd/util");

// src/FeedCredential.ts
var import_semaphore_signature_pcd = require("@pcd/semaphore-signature-pcd");
var import_util = require("@pcd/util");
var TIMESTAMP_MAX_AGE = import_util.ONE_HOUR_MS;
function createFeedCredentialPayload(pcd) {
  return {
    pcd,
    timestamp: Date.now()
  };
}
function validateFeedCredentialTimestamp(timestamp) {
  const now = Date.now();
  return now - timestamp < TIMESTAMP_MAX_AGE;
}
function deserializeAndVerify(serializedPCD) {
  return __async(this, null, function* () {
    const pcd = yield import_semaphore_signature_pcd.SemaphoreSignaturePCDPackage.deserialize(serializedPCD.pcd);
    return yield import_semaphore_signature_pcd.SemaphoreSignaturePCDPackage.verify(pcd);
  });
}
function verifyFeedCredential(serializedPCD, pcdVerifier) {
  return __async(this, null, function* () {
    if (pcdVerifier === void 0) {
      pcdVerifier = deserializeAndVerify;
    }
    if (!(yield pcdVerifier(serializedPCD))) {
      throw new Error(`Could not verify SemaphoreSignaturePCD`);
    }
    const pcd = yield import_semaphore_signature_pcd.SemaphoreSignaturePCDPackage.deserialize(serializedPCD.pcd);
    const payload = JSON.parse(pcd.claim.signedMessage);
    if (!validateFeedCredentialTimestamp(payload.timestamp)) {
      throw new Error("Credential timestamp out of bounds");
    }
    return { pcd, payload };
  });
}

// src/util/StorageBackedMap.ts
var StorageBackedMap = class extends Map {
  constructor(storageKey) {
    super();
    this.storageKey = storageKey;
    this.syncing = false;
    this.reloadFromStorage();
    window.addEventListener("storage", (ev) => {
      if (ev.key === null) {
        super.clear();
      } else if (ev.key === this.storageKey) {
        this.reloadFromStorage();
      }
    });
  }
  /**
   * Queues a microtask to sync to local storage once the current event loop
   *  has finished processing
   */
  queueSync() {
    if (!this.syncing) {
      this.syncing = true;
      queueMicrotask(() => this.syncToStorage());
    }
  }
  /**
   * Sync the map entries to local storage
   */
  syncToStorage() {
    const data = JSON.stringify(Array.from(this.entries()));
    if (window.localStorage.getItem(this.storageKey) !== data) {
      window.localStorage.setItem(this.storageKey, data);
    }
    this.syncing = false;
  }
  /**
   * Reloads data from storage, called in response to storage changes that
   * come from other tabs.
   */
  reloadFromStorage() {
    const storageData = window.localStorage.getItem(this.storageKey);
    let loadedData = [];
    if (storageData) {
      try {
        const parsed = JSON.parse(storageData);
        if (parsed instanceof Array) {
          loadedData = parsed;
        }
      } catch (e) {
      }
    }
    super.clear();
    loadedData.forEach((entry) => {
      super.set(entry[0], entry[1]);
    });
  }
  /**
   * Wraps Map.set(), and queues a sync after the change
   */
  set(key, value) {
    super.set(key, value);
    this.queueSync();
    return this;
  }
  /**
   * Wraps Map.delete(), and queues a sync after the change
   */
  delete(key) {
    if (super.delete(key)) {
      this.queueSync();
      return true;
    }
    return false;
  }
  /**
   * Wraps Map.clear(), and queues a sync after the change
   */
  clear() {
    super.clear();
    this.queueSync();
  }
  /**
   * Wraps Map.forEach(), and queues a sync.
   * This is necessary because callbackfn can mutate map entries.
   */
  forEach(callbackfn, thisArg) {
    super.forEach(callbackfn, thisArg);
    this.queueSync();
  }
};

// src/CredentialManager.ts
var CACHE_TTL = import_util2.ONE_HOUR_MS;
function createCredentialCache() {
  return /* @__PURE__ */ new Map();
}
function createStorageBackedCredentialCache() {
  return new StorageBackedMap("credential-cache");
}
var CredentialManager = class {
  constructor(identity, pcds, cache) {
    this.identity = identity;
    this.pcds = pcds;
    this.cache = cache;
  }
  // Can we get a credential containing a given PCD type?
  canGenerateCredential(req) {
    if (req.pcdType === "email-pcd") {
      return this.pcds.getPCDsByType(req.pcdType).length !== 0;
    } else if (req.pcdType === void 0) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Before doing a parallel fetching of subscriptions, it can be helpful to
   * prepare the credentials to avoid race conditions.
   */
  prepareCredentials(reqs) {
    return __async(this, null, function* () {
      for (const req of reqs) {
        if (!this.getCachedCredential(req.pcdType)) {
          this.setCachedCredential(
            req.pcdType,
            yield this.generateCredential(req)
          );
        }
      }
    });
  }
  // Get a credential from the local cache, if it exists
  getCachedCredential(type) {
    const cacheKey = type != null ? type : "none";
    const res = this.cache.get(cacheKey);
    if (res) {
      if (Date.now() - res.timestamp < CACHE_TTL) {
        return res.value;
      } else {
        this.cache.delete(cacheKey);
      }
    }
    return void 0;
  }
  // Adds a credential to the cache
  setCachedCredential(type, value) {
    const cacheKey = type != null ? type : "none";
    this.cache.set(cacheKey, { value, timestamp: Date.now() });
    this.purgeExpiredCredentials();
  }
  // Purges expired items from the cache
  purgeExpiredCredentials() {
    return __async(this, null, function* () {
      const keysToRemove = [];
      this.cache.forEach((v, k) => {
        if (Date.now() - v.timestamp >= CACHE_TTL) {
          keysToRemove.push(k);
        }
      });
      keysToRemove.forEach((key) => this.cache.delete(key));
    });
  }
  /**
   * Returns a requested credential, either from the cache or by generating it.
   */
  requestCredential(req) {
    return __async(this, null, function* () {
      const cachedCredential = this.getCachedCredential(req.pcdType);
      if (cachedCredential) {
        return cachedCredential;
      }
      const credential = yield this.generateCredential(req);
      this.setCachedCredential(req.pcdType, credential);
      return credential;
    });
  }
  /**
   * Generates the requested credential, if possible.
   * Takes a {@link CredentialRequest} and produces a serialized PCD which
   * consists of a signature PCD (e.g. a semaphore signature PCD) which wraps
   * a {@link FeedCredentialPayload}. This payload contains a timestamp, and
   * may contain a PCD if a) the feed requests one and b) CredentialManager
   * can find a matching PCD.
   */
  generateCredential(req) {
    return __async(this, null, function* () {
      if (req.pcdType === "email-pcd") {
        const pcds = this.pcds.getPCDsByType(req.pcdType);
        if (pcds.length === 0) {
          throw new Error(
            `Could not find a PCD of type ${req.pcdType} for credential payload`
          );
        }
        const pcd = pcds[0];
        const serializedPCD = yield this.pcds.serialize(pcd);
        return this.semaphoreSignPayload(
          createFeedCredentialPayload(serializedPCD)
        );
      } else if (req.pcdType === void 0) {
        return this.semaphoreSignPayload(createFeedCredentialPayload());
      } else {
        throw new Error(
          `Cannot issue credential containing a PCD of type ${req.pcdType}`
        );
      }
    });
  }
  // Takes a payload and wraps it in a signature PCD.
  semaphoreSignPayload(payload) {
    return __async(this, null, function* () {
      const signaturePCD = yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.prove({
        identity: {
          argumentType: import_pcd_types.ArgumentTypeName.PCD,
          value: yield import_semaphore_identity_pcd.SemaphoreIdentityPCDPackage.serialize(
            yield import_semaphore_identity_pcd.SemaphoreIdentityPCDPackage.prove({
              identity: this.identity
            })
          )
        },
        signedMessage: {
          argumentType: import_pcd_types.ArgumentTypeName.String,
          value: JSON.stringify(payload)
        }
      });
      return yield import_semaphore_signature_pcd2.SemaphoreSignaturePCDPackage.serialize(signaturePCD);
    });
  }
};

// src/EncryptedStorage.ts
var import_passport_crypto2 = require("@pcd/passport-crypto");
var import_pcd_collection2 = require("@pcd/pcd-collection");
var import_fast_json_stable_stringify2 = __toESM(require("fast-json-stable-stringify"));

// src/api/makeRequest.ts
var import_util3 = require("@pcd/util");
var import_url_join = __toESM(require("url-join"));

// src/api/constants.ts
var POST = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  method: "POST"
};

// src/api/makeRequest.ts
function httpGet(url, opts, query) {
  return __async(this, null, function* () {
    return httpRequest(
      (0, import_url_join.default)(
        url,
        "?" + new URLSearchParams(query != null ? query : {})
      ),
      opts
    );
  });
}
function httpPost(url, opts, postBody) {
  return __async(this, null, function* () {
    return httpRequest(url, opts, postBody);
  });
}
function httpGetSimple(url, onValue, query) {
  return __async(this, null, function* () {
    return httpGet(
      url,
      {
        onValue,
        onError: (resText) => __async(this, null, function* () {
          return { error: resText, success: false };
        })
      },
      query
    );
  });
}
function httpPostSimple(url, onValue, postBody) {
  return __async(this, null, function* () {
    return httpPost(
      url,
      {
        onValue,
        onError: (resText) => __async(this, null, function* () {
          return { error: resText, success: false };
        })
      },
      postBody
    );
  });
}
var throttleMs = 0;
function httpRequest(url, opts, postBody) {
  return __async(this, null, function* () {
    yield (0, import_util3.sleep)(throttleMs);
    let requestOptions = {
      method: "GET"
    };
    if (postBody != null) {
      requestOptions = __spreadProps(__spreadValues(__spreadValues({}, requestOptions), POST), {
        body: JSON.stringify(postBody)
      });
    }
    try {
      console.log(`[FETCH]`, url, requestOptions.method);
      const res = yield fetch(url, requestOptions);
      const resText = yield res.text();
      if (!res.ok) {
        console.error("error fetching", url, res.status, resText);
        return yield opts.onError(resText, res.status);
      }
      return yield opts.onValue(resText);
    } catch (e) {
      console.error("error fetching", url, e);
      try {
        return yield opts.onError((0, import_util3.getErrorMessage)(e), void 0);
      } catch (e2) {
        console.error("[FETCH] error executing `opts.onError`", e2);
        throw e2;
      }
    }
  });
}
function mapStringError(resText) {
  return __async(this, null, function* () {
    return { error: resText, success: false };
  });
}

// src/api/requestListFeeds.ts
function requestListFeeds(url) {
  return __async(this, null, function* () {
    return httpGetSimple(url, (resText) => __async(this, null, function* () {
      return {
        value: JSON.parse(resText),
        success: true
      };
    }));
  });
}

// src/api/requestPollFeed.ts
var import_pcd_types2 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd2 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd3 = require("@pcd/semaphore-signature-pcd");
function requestPollFeed(url, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      url,
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}
function pollFeed(feedUrl, identity, signedMessage, feedId) {
  return __async(this, null, function* () {
    return requestPollFeed(feedUrl, {
      feedId,
      pcd: yield import_semaphore_signature_pcd3.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd3.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types2.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd2.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd2.SemaphoreIdentityPCDPackage.prove({
                identity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types2.ArgumentTypeName.String,
            value: signedMessage
          }
        })
      )
    });
  });
}

// src/FeedAPI.ts
var NetworkFeedApi = class {
  pollFeed(providerUrl, request) {
    return __async(this, null, function* () {
      return requestPollFeed(providerUrl, request);
    });
  }
  listFeeds(providerUrl) {
    return __async(this, null, function* () {
      return requestListFeeds(providerUrl);
    });
  }
};

// src/SubscriptionManager.ts
var import_emitter = require("@pcd/emitter");
var import_passport_crypto = require("@pcd/passport-crypto");
var import_pcd_collection = require("@pcd/pcd-collection");
var import_util4 = require("@pcd/util");
var import_fast_json_stable_stringify = __toESM(require("fast-json-stable-stringify"));
var import_uuid = require("uuid");
var ZupassFeedIds = /* @__PURE__ */ ((ZupassFeedIds2) => {
  ZupassFeedIds2["Devconnect"] = "1";
  ZupassFeedIds2["Frogs"] = "2";
  ZupassFeedIds2["Email"] = "3";
  ZupassFeedIds2["Zuzalu_23"] = "4";
  ZupassFeedIds2["Zuconnect_23"] = "5";
  return ZupassFeedIds2;
})(ZupassFeedIds || {});
function applyActions(collection, actions) {
  return __async(this, null, function* () {
    for (const actionSet of actions) {
      for (const action of actionSet.actions) {
        yield collection.tryExec(action, actionSet.subscription.feed.permissions);
      }
    }
  });
}
function ensureHasId(sub) {
  if (!sub.id) {
    sub.id = (0, import_uuid.v4)();
  }
  return sub;
}
var FeedSubscriptionManager = class {
  constructor(api, providers, activeSubscriptions) {
    this.updatedEmitter = new import_emitter.Emitter();
    this.api = api;
    this.providers = providers != null ? providers : [];
    this.activeSubscriptions = (activeSubscriptions != null ? activeSubscriptions : []).map(ensureHasId);
    this.errors = /* @__PURE__ */ new Map();
  }
  listFeeds(providerUrl) {
    return __async(this, null, function* () {
      return this.api.listFeeds(providerUrl).then((r) => {
        if (r.success) {
          return r.value;
        } else {
          throw new Error(r.error);
        }
      });
    });
  }
  /**
   * This "refreshes" a feed. Existing feed errors are cleared, and new
   * ones may be detected.
   *
   * Returns the successful responses. Failures will be recorded in
   * `this.errors` for display to the user.
   */
  pollSubscriptions(credentialManager) {
    return __async(this, null, function* () {
      const responsePromises = [];
      yield credentialManager.prepareCredentials(
        this.activeSubscriptions.map((sub) => sub.feed.credentialRequest)
      );
      for (const subscription of this.activeSubscriptions) {
        if (subscription.feed.autoPoll === false) {
          continue;
        }
        responsePromises.push(
          this.fetchSingleSubscription(subscription, credentialManager)
        );
      }
      const responses = (yield Promise.allSettled(responsePromises)).filter(import_util4.isFulfilled).flatMap((result) => result.value);
      this.updatedEmitter.emit();
      return responses;
    });
  }
  /**
   * Poll a single subscription. Intended for use when resolving errors
   * with a feed that failed to load due to network/connection issues.
   */
  pollSingleSubscription(subscription, credentialManager) {
    return __async(this, null, function* () {
      const actions = yield this.fetchSingleSubscription(
        subscription,
        credentialManager
      );
      this.updatedEmitter.emit();
      return actions;
    });
  }
  /**
   * Performs the network fetch of a subscription, and inspects the results
   * for validity. The error log for the subscription will be reset and
   * repopulated, so callers should check this in order to determine success.
   */
  fetchSingleSubscription(subscription, credentialManager) {
    return __async(this, null, function* () {
      const responses = [];
      this.resetError(subscription.id);
      try {
        const result = yield this.api.pollFeed(subscription.providerUrl, {
          feedId: subscription.feed.id,
          pcd: yield credentialManager.requestCredential({
            signatureType: "sempahore-signature-pcd",
            pcdType: subscription.feed.credentialRequest.pcdType
          })
        });
        if (!result.success) {
          throw new Error(result.error);
        }
        const { actions } = result.value;
        this.validateActions(subscription, actions);
        responses.push({
          actions,
          subscription
        });
      } catch (e) {
        this.setError(subscription.id, {
          type: "fetch-error" /* FetchError */,
          e: e instanceof Error ? e : void 0
        });
      }
      return responses;
    });
  }
  /**
   * Validates that the actions received in a feed are permitted by the user.
   */
  validateActions(subscription, actions) {
    const grantedPermissions = subscription.feed.permissions;
    const failedActions = [];
    for (const action of actions) {
      if (!(0, import_pcd_collection.matchActionToPermission)(action, grantedPermissions)) {
        failedActions.push(action);
      }
    }
    if (failedActions.length > 0) {
      console.log(subscription);
      this.setError(subscription.id, {
        type: "permission-error" /* PermissionError */,
        actions: failedActions
      });
    }
  }
  getSubscriptionsByProvider() {
    var _a;
    const result = /* @__PURE__ */ new Map();
    const providers = this.getProviders();
    for (const provider of providers) {
      const array = (_a = result.get(provider.providerUrl)) != null ? _a : [];
      result.set(provider.providerUrl, array);
      array.push(
        ...this.activeSubscriptions.filter(
          (s) => s.providerUrl === provider.providerUrl
        )
      );
    }
    return result;
  }
  unsubscribe(subscriptionId) {
    const existingSubscription = this.getSubscription(subscriptionId);
    if (!existingSubscription) {
      throw new Error(`no subscription with id ${subscriptionId}`);
    }
    this.activeSubscriptions = this.activeSubscriptions.filter(
      (s) => s.id !== subscriptionId
    );
    this.errors.delete(existingSubscription.id);
    const remainingSubscriptionsOnProvider = this.getSubscriptionsForProvider(
      existingSubscription.providerUrl
    );
    if (remainingSubscriptionsOnProvider.length === 0) {
      this.removeProvider(existingSubscription.providerUrl);
    }
    this.updatedEmitter.emit();
  }
  removeProvider(providerUrl) {
    const subscriptions = this.getSubscriptionsForProvider(providerUrl);
    if (subscriptions.length > 0) {
      throw new Error(
        `can't remove provider ${providerUrl} - have ${subscriptions.length} existing subscriptions`
      );
    }
    this.providers = this.providers.filter(
      (p) => p.providerUrl !== providerUrl
    );
    this.updatedEmitter.emit();
  }
  getSubscriptionsForProvider(providerUrl) {
    return this.activeSubscriptions.filter(
      (s) => s.providerUrl === providerUrl
    );
  }
  findSubscription(providerUrl, feedId) {
    return this.activeSubscriptions.find((sub) => {
      sub.providerUrl === providerUrl && sub.id === feedId;
    });
  }
  subscribe(providerUrl, info, replace) {
    return __async(this, null, function* () {
      if (!this.hasProvider(providerUrl)) {
        throw new Error(`provider ${providerUrl} does not exist`);
      }
      const providerSubs = this.getSubscriptionsByProvider().get(providerUrl);
      const existingSubscription = providerSubs && providerSubs.find((sub2) => sub2.feed.id === info.id);
      if (existingSubscription && !replace) {
        throw new Error(
          `already subscribed on provider ${providerUrl} to feed ${info.id} `
        );
      }
      if (info.credentialRequest.pcdType && info.credentialRequest.pcdType !== "email-pcd") {
        throw new Error(
          `non-supported credential PCD requested on ${providerUrl} feed ${info.id}`
        );
      }
      let sub;
      if (existingSubscription) {
        sub = existingSubscription;
        sub.feed = __spreadValues({}, info);
        sub.providerUrl = providerUrl;
      } else {
        sub = {
          id: (0, import_uuid.v4)(),
          feed: __spreadValues({}, info),
          providerUrl,
          subscribedTimestamp: Date.now()
        };
        this.activeSubscriptions.push(sub);
      }
      this.updatedEmitter.emit();
      return sub;
    });
  }
  updateFeedPermissionsForSubscription(subscriptionId, permissions) {
    const sub = this.getSubscription(subscriptionId);
    if (!sub) {
      throw new Error(`no subscription found matching ${subscriptionId}`);
    }
    sub.feed.permissions = permissions;
    this.updatedEmitter.emit();
  }
  getSubscription(subscriptionId) {
    return this.activeSubscriptions.find((s) => s.id === subscriptionId);
  }
  getSubscriptionsByProviderAndFeedId(providerUrl, feedId) {
    return this.activeSubscriptions.filter(
      (s) => s.feed.id === feedId && s.providerUrl === providerUrl
    );
  }
  hasProvider(providerUrl) {
    return this.getProvider(providerUrl) !== void 0;
  }
  getProvider(providerUrl) {
    return this.providers.find((p) => p.providerUrl === providerUrl);
  }
  getOrAddProvider(providerUrl, providerName) {
    const existingProvider = this.getProvider(providerUrl);
    if (existingProvider) {
      return existingProvider;
    }
    return this.addProvider(providerUrl, providerName);
  }
  addProvider(providerUrl, providerName) {
    if (this.hasProvider(providerUrl)) {
      throw new Error(`provider ${providerUrl} already exists`);
    }
    const newProvider = {
      providerUrl,
      providerName,
      timestampAdded: Date.now()
    };
    this.providers.push(newProvider);
    this.updatedEmitter.emit();
    return newProvider;
  }
  getProviders() {
    return this.providers;
  }
  getActiveSubscriptions() {
    return this.activeSubscriptions;
  }
  serialize() {
    return (0, import_fast_json_stable_stringify.default)({
      providers: this.providers,
      subscribedFeeds: this.activeSubscriptions,
      _storage_version: "v1"
    });
  }
  /**
   * Create a FeedSubscriptionManager from serialized data.
   * Upgrades from serialized data based on version number.
   */
  static deserialize(api, serialized) {
    var _a, _b, _c, _d;
    const parsed = JSON.parse(serialized);
    if (parsed._storage_version === void 0) {
      const providers = (_a = parsed.providers) != null ? _a : [];
      const subscribedFeeds = ((_b = parsed.subscribedFeeds) != null ? _b : []).map(
        (sub) => {
          const feed = {
            id: sub.feed.id,
            name: sub.feed.name,
            description: sub.feed.description,
            permissions: sub.feed.permissions,
            credentialRequest: __spreadValues({
              signatureType: "sempahore-signature-pcd"
            }, sub.feed.credentialType === "email-pcd" ? { pcdType: sub.feed.credentialType } : {})
          };
          return {
            id: sub.id,
            feed,
            providerUrl: sub.providerUrl,
            subscribedTimestamp: sub.subscribedTimestamp
          };
        }
      );
      return new FeedSubscriptionManager(api, providers, subscribedFeeds);
    }
    return new FeedSubscriptionManager(
      api,
      (_c = parsed.providers) != null ? _c : [],
      (_d = parsed.subscribedFeeds) != null ? _d : []
    );
  }
  setError(subscriptionId, error) {
    console.log({ subscriptionId, error });
    this.errors.set(subscriptionId, error);
  }
  resetError(subscriptionId) {
    this.errors.delete(subscriptionId);
  }
  getError(subscriptionId) {
    var _a;
    return (_a = this.errors.get(subscriptionId)) != null ? _a : null;
  }
  getAllErrors() {
    return this.errors;
  }
  getHash() {
    return __async(this, null, function* () {
      return yield (0, import_passport_crypto.getHash)(this.serialize());
    });
  }
};
var SubscriptionErrorType = /* @__PURE__ */ ((SubscriptionErrorType2) => {
  SubscriptionErrorType2["PermissionError"] = "permission-error";
  SubscriptionErrorType2["FetchError"] = "fetch-error";
  return SubscriptionErrorType2;
})(SubscriptionErrorType || {});

// src/EncryptedStorage.ts
function isSyncedEncryptedStorageV1(storage) {
  return storage._storage_version === void 0;
}
function isSyncedEncryptedStorageV2(storage) {
  return storage._storage_version === "v2";
}
function isSyncedEncryptedStorageV3(storage) {
  return storage._storage_version === "v3";
}
function deserializeStorage(storage, pcdPackages) {
  return __async(this, null, function* () {
    let pcds;
    let subscriptions;
    if (isSyncedEncryptedStorageV3(storage)) {
      pcds = yield import_pcd_collection2.PCDCollection.deserialize(pcdPackages, storage.pcds);
      subscriptions = FeedSubscriptionManager.deserialize(
        new NetworkFeedApi(),
        storage.subscriptions
      );
    } else if (isSyncedEncryptedStorageV2(storage)) {
      pcds = yield import_pcd_collection2.PCDCollection.deserialize(pcdPackages, storage.pcds);
      subscriptions = new FeedSubscriptionManager(new NetworkFeedApi());
    } else if (isSyncedEncryptedStorageV1(storage)) {
      pcds = new import_pcd_collection2.PCDCollection(pcdPackages);
      yield pcds.deserializeAllAndAdd(storage.pcds);
      subscriptions = new FeedSubscriptionManager(new NetworkFeedApi());
    } else {
      throw new Error(
        `Unknown SyncedEncryptedStorage version 
      ${storage["_storage_version"]}`
      );
    }
    return {
      pcds,
      subscriptions,
      storageHash: yield getStorageHash(storage)
    };
  });
}
function serializeStorage(user, pcds, subscriptions) {
  return __async(this, null, function* () {
    const serializedStorage = {
      pcds: yield pcds.serializeCollection(),
      self: user,
      subscriptions: subscriptions.serialize(),
      _storage_version: "v3"
    };
    return {
      serializedStorage,
      storageHash: yield getStorageHash(serializedStorage)
    };
  });
}
function getStorageHash(storage) {
  return __async(this, null, function* () {
    return yield (0, import_passport_crypto2.getHash)((0, import_fast_json_stable_stringify2.default)(storage));
  });
}

// src/FeedHost.ts
var FeedHost = class {
  constructor(feeds, providerUrl, providerName) {
    this.hostedFeed = feeds;
    this.providerUrl = providerUrl;
    this.providerName = providerName;
  }
  getProviderUrl() {
    return this.providerUrl;
  }
  getProviderName() {
    return this.providerName;
  }
  handleFeedRequest(request) {
    return __async(this, null, function* () {
      const feed = this.hostedFeed.find((f) => f.feed.id === request.feedId);
      if (!feed) {
        throw new Error(`couldn't find feed with id ${request.feedId}`);
      }
      const response = yield feed.handleRequest(request);
      return response;
    });
  }
  handleListFeedsRequest(_request) {
    return __async(this, null, function* () {
      return {
        providerName: this.providerName,
        providerUrl: this.providerUrl,
        feeds: this.hostedFeed.map((f) => f.feed)
      };
    });
  }
  hasFeedWithId(feedId) {
    return this.hostedFeed.filter((f) => f.feed.id === feedId).length > 0;
  }
  handleListSingleFeedRequest(_request) {
    return __async(this, null, function* () {
      return {
        providerUrl: this.providerUrl,
        providerName: this.providerName,
        feeds: this.hostedFeed.filter((f) => f.feed.id === _request.feedId).map((f) => f.feed)
      };
    });
  }
};

// src/FrogCrypto.ts
var import_eddsa_frog_pcd = require("@pcd/eddsa-frog-pcd");
var import_lodash = __toESM(require("lodash"));
var import_zod = require("zod");
var FROG_FREEROLLS = 2;
var FROG_SCORE_CAP = 1e3;
var FrogCryptoFeedBiomeConfigSchema = import_zod.z.object({
  /**
   * A scaling factor that is multiplied to the weight of the frog to affect
   * the probability of the frog being issued
   *
   * For example, if a feed has 3 frogs:
   *
   * * JungleFrog1's drop weight is 1
   * * JungleFrog2's drop weight is 2
   * * DesertFrog3's drop weight is 3
   *
   *  If the Jungle's dropWeightScaler is 2 and the Desert's
   *   dropWeightScaler is 1, then
   *
   * * JungleFrog1's probability of being issued is 2/9
   * * JungleFrog2's probability of being issued is 4/9
   * * DesertFrog3's probability of being issued is 3/9
   */
  dropWeightScaler: import_zod.z.number().nonnegative()
});
var FrogCryptoFeedBiomeConfigsSchema = import_zod.z.object(
  import_lodash.default.mapValues(import_eddsa_frog_pcd.Biome, () => FrogCryptoFeedBiomeConfigSchema.optional())
);
var IFrogCryptoFeedSchema = import_zod.z.object({
  /**
   * Whether this feed is discoverable in GET /feeds
   *
   * A feed can still be queried as GET /feeds/:feedId or polled as POST /feeds even if it is not discoverable
   * as long as the user knows the feed ID.
   * @default false
   */
  private: import_zod.z.boolean(),
  /**
   * Unix timestamp in seconds of when this feed will become inactive
   *
   * PCD can only be issued from this feed if it is active
   * @default 0 means the feed is inactive
   */
  activeUntil: import_zod.z.number().nonnegative().int(),
  /**
   * How long to wait between each PCD issuance in seconds
   */
  cooldown: import_zod.z.number().nonnegative().int(),
  /**
   * Map of configs for Biome(s) where PCDs can be issued from this feed
   */
  biomes: FrogCryptoFeedBiomeConfigsSchema,
  /**
   * A list of secret codes that can be used to look up this feed
   */
  codes: import_zod.z.array(import_zod.z.string()).optional()
});
var IFrogCryptoClientFeedSchema = import_zod.z.object({
  private: IFrogCryptoFeedSchema.shape.private,
  activeUntil: IFrogCryptoFeedSchema.shape.activeUntil,
  cooldown: IFrogCryptoFeedSchema.shape.cooldown
});
var FrogCryptoDbFeedDataSchema = import_zod.z.object({
  uuid: import_zod.z.string().uuid(),
  feed: import_zod.z.object({
    name: import_zod.z.string().min(1),
    description: import_zod.z.string().min(1),
    private: import_zod.z.boolean(),
    activeUntil: import_zod.z.number().nonnegative().int(),
    cooldown: import_zod.z.number().nonnegative().int(),
    biomes: FrogCryptoFeedBiomeConfigsSchema,
    codes: import_zod.z.array(import_zod.z.string()).optional()
  })
});
var FrogCryptoFrogDataSchema = import_zod.z.object({
  id: import_zod.z.number().nonnegative().int(),
  uuid: import_zod.z.string().uuid(),
  name: import_zod.z.string().min(1),
  description: import_zod.z.string().min(1),
  biome: import_zod.z.string().min(1),
  rarity: import_zod.z.string().min(1),
  /**
   * undefined means the temperament will be randomly selected
   */
  temperament: import_zod.z.string().optional(),
  drop_weight: import_zod.z.number().nonnegative(),
  jump_min: import_zod.z.number().gte(0).lte(15).optional(),
  jump_max: import_zod.z.number().gte(0).lte(15).optional(),
  speed_min: import_zod.z.number().gte(0).lte(15).optional(),
  speed_max: import_zod.z.number().gte(0).lte(15).optional(),
  intelligence_min: import_zod.z.number().gte(0).lte(15).optional(),
  intelligence_max: import_zod.z.number().gte(0).lte(15).optional(),
  beauty_min: import_zod.z.number().gte(0).lte(15).optional(),
  beauty_max: import_zod.z.number().gte(0).lte(15).optional()
});
var FrogCryptoFolderName = "FrogCrypto";

// src/PassportInterface.ts
var PCDRequestType = /* @__PURE__ */ ((PCDRequestType2) => {
  PCDRequestType2["Get"] = "Get";
  PCDRequestType2["GetWithoutProving"] = "GetWithoutProving";
  PCDRequestType2["Add"] = "Add";
  PCDRequestType2["ProveAndAdd"] = "ProveAndAdd";
  return PCDRequestType2;
})(PCDRequestType || {});
function getWithoutProvingUrl(zupassClientUrl, returnUrl, pcdType) {
  const req = {
    type: "GetWithoutProving" /* GetWithoutProving */,
    pcdType,
    returnUrl
  };
  const encReq = encodeURIComponent(JSON.stringify(req));
  return `${zupassClientUrl}#/get-without-proving?request=${encReq}`;
}
function constructZupassPcdGetRequestUrl(zupassClientUrl, returnUrl, pcdType, args, options) {
  const req = {
    type: "Get" /* Get */,
    returnUrl,
    args,
    pcdType,
    options
  };
  const encReq = encodeURIComponent(JSON.stringify(req));
  return `${zupassClientUrl}#/prove?request=${encReq}`;
}
function constructZupassPcdAddRequestUrl(zupassClientUrl, returnUrl, pcd) {
  const req = {
    type: "Add" /* Add */,
    returnUrl,
    pcd
  };
  const eqReq = encodeURIComponent(JSON.stringify(req));
  return `${zupassClientUrl}#/add?request=${eqReq}`;
}
function constructZupassPcdProveAndAddRequestUrl(zupassClientUrl, returnUrl, pcdType, args, options, returnPCD) {
  const req = {
    type: "ProveAndAdd" /* ProveAndAdd */,
    returnUrl,
    pcdType,
    args,
    options,
    returnPCD
  };
  const eqReq = encodeURIComponent(JSON.stringify(req));
  return `${zupassClientUrl}#/add?request=${eqReq}`;
}
var PayloadType = /* @__PURE__ */ ((PayloadType2) => {
  PayloadType2["RedirectTopicData"] = "topic-data";
  PayloadType2["NullifierHash"] = "nullifier-hash";
  PayloadType2["AnonTopicDataPayload"] = "anon-topic-data-payload";
  PayloadType2["ReactData"] = "react-data";
  return PayloadType2;
})(PayloadType || {});

// src/PassportPopup.ts
var import_react = require("react");
function useZupassPopupMessages() {
  const [pcdStr, setPCDStr] = (0, import_react.useState)("");
  const [pendingPCDStr, setPendingPCDStr] = (0, import_react.useState)("");
  (0, import_react.useEffect)(() => {
    function receiveMessage(ev) {
      if (ev.data.encodedPCD) {
        console.log("Received PCD", ev.data.encodedPCD);
        setPCDStr(ev.data.encodedPCD);
      } else if (ev.data.encodedPendingPCD) {
        console.log(ev.data);
        setPendingPCDStr(ev.data.encodedPendingPCD);
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage);
  }, []);
  return [pcdStr, pendingPCDStr];
}
function useZupassPopupSetup() {
  const [error, setError] = (0, import_react.useState)("");
  (0, import_react.useEffect)(() => {
    if (window.opener == null) {
      setError("Not a popup window");
      return;
    }
    let params;
    if (window.location.href.includes(window.location.origin + "/#/")) {
      const url = new URL(window.location.href.replace("#", ""));
      params = url.searchParams;
    } else {
      params = new URLSearchParams(window.location.search);
    }
    const paramsProofUrl = params.get("proofUrl");
    const paramsProof = params.get("proof");
    const paramsEncodingPendingPCD = params.get("encodedPendingPCD");
    const finished = params.get("finished");
    if (paramsProofUrl != null) {
      window.location.href = paramsProofUrl;
    } else if (finished) {
      if (paramsProof != null) {
        window.opener.postMessage({ encodedPCD: paramsProof }, "*");
      }
      window.close();
      setTimeout(() => {
        setError("Finished. Please close this window.");
      }, 1e3 * 3);
    } else if (paramsEncodingPendingPCD != null) {
      window.opener.postMessage(
        { encodedPendingPCD: paramsEncodingPendingPCD },
        "*"
      );
      window.close();
      setTimeout(() => {
        setError("Finished. Please close this window.");
      }, 1e3 * 3);
    }
  }, []);
  return error;
}
function openZupassPopup(popupUrl, proofUrl) {
  const url = `${popupUrl}?proofUrl=${encodeURIComponent(proofUrl)}`;
  window.open(url, "_blank", "width=450,height=600,top=100,popup");
}

// src/PendingPCDIntegration.ts
var import_react2 = require("react");

// src/api/requestServerProofStatus.ts
var import_url_join2 = __toESM(require("url-join"));
function requestServerProofStatus(zupassServerUrl, proveRequest) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join2.default)(zupassServerUrl, `/pcds/status`),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      proveRequest
    );
  });
}

// src/PendingPCDUtils.ts
var import_js_sha256 = require("js-sha256");
function hashProveRequest(req) {
  const reqString = JSON.stringify(req);
  return (0, import_js_sha256.sha256)(reqString);
}
var PendingPCDStatus = /* @__PURE__ */ ((PendingPCDStatus2) => {
  PendingPCDStatus2["QUEUED"] = "queued";
  PendingPCDStatus2["PROVING"] = "proving";
  PendingPCDStatus2["COMPLETE"] = "complete";
  PendingPCDStatus2["ERROR"] = "error";
  PendingPCDStatus2["NONE"] = "none";
  return PendingPCDStatus2;
})(PendingPCDStatus || {});
function isSettledPendingPCDStatus(status) {
  return [
    "error" /* ERROR */,
    "complete" /* COMPLETE */,
    "none" /* NONE */
  ].includes(status);
}

// src/PendingPCDIntegration.ts
function usePendingPCD(pendingPCDStr, zupassServerUrl) {
  const [pendingPCDStatus, setPendingPCDStatus] = (0, import_react2.useState)(
    "none" /* NONE */
  );
  const [pendingPCDError, setPendingPCDError] = (0, import_react2.useState)("");
  const [pcdStr, setPCDStr] = (0, import_react2.useState)("");
  (0, import_react2.useEffect)(() => {
    let interval = void 0;
    const getProofStatus = () => __async(this, null, function* () {
      if (pendingPCDStr !== void 0 && pendingPCDStr !== "") {
        const pendingPCD = JSON.parse(pendingPCDStr);
        const proofStatusResult = yield requestServerProofStatus(
          zupassServerUrl,
          {
            hash: pendingPCD.hash
          }
        );
        if (!proofStatusResult.success) {
          setPendingPCDStatus("error" /* ERROR */);
          setPendingPCDError(proofStatusResult.error);
          clearInterval(interval);
          return;
        }
        setPendingPCDStatus(proofStatusResult.value.status);
        if (proofStatusResult.value.status === "complete" /* COMPLETE */ && proofStatusResult.value.serializedPCD !== void 0) {
          setPCDStr(proofStatusResult.value.serializedPCD);
          setPendingPCDError("");
          clearInterval(interval);
        } else if (proofStatusResult.value.status === "error" /* ERROR */ && proofStatusResult.value.error !== void 0) {
          setPendingPCDError(proofStatusResult.value.error);
          clearInterval(interval);
        }
      }
    });
    interval = setInterval(getProofStatus, 1e3);
    return () => clearInterval(interval);
  }, [pendingPCDStr, zupassServerUrl]);
  return [pendingPCDStatus, pendingPCDError, pcdStr];
}
function usePCDMultiplexer(zupassPCDStr, serverPCDStr) {
  const [pcdStr, setPCDStr] = (0, import_react2.useState)("");
  (0, import_react2.useEffect)(() => {
    console.log(zupassPCDStr);
    if (zupassPCDStr) {
      setPCDStr(zupassPCDStr);
    } else if (serverPCDStr) {
      setPCDStr(serverPCDStr);
    }
  }, [zupassPCDStr, serverPCDStr]);
  return pcdStr;
}

// src/RequestTypes.ts
var KnownTicketGroup = /* @__PURE__ */ ((KnownTicketGroup2) => {
  KnownTicketGroup2["Devconnect23"] = "Devconnect23";
  KnownTicketGroup2["Zuzalu23"] = "Zuzalu23";
  KnownTicketGroup2["Zuconnect23"] = "Zuconnect23";
  return KnownTicketGroup2;
})(KnownTicketGroup || {});
var KnownPublicKeyType = /* @__PURE__ */ ((KnownPublicKeyType2) => {
  KnownPublicKeyType2["EdDSA"] = "eddsa";
  KnownPublicKeyType2["RSA"] = "rsa";
  return KnownPublicKeyType2;
})(KnownPublicKeyType || {});
var ISSUANCE_STRING = "Issue me PCDs please.";
function defaultOfflineTickets() {
  return {
    devconnectTickets: []
  };
}

// src/SemaphoreGroupIntegration.ts
var import_pcd_types3 = require("@pcd/pcd-types");
var import_semaphore_group_pcd = require("@pcd/semaphore-group-pcd");
var import_semaphore_identity_pcd3 = require("@pcd/semaphore-identity-pcd");
var import_util5 = require("@pcd/util");
var import_react4 = require("react");

// src/api/requestSemaphoreGroup.ts
function requestSemaphoreGroup(semaphoreGroupUrl) {
  return __async(this, null, function* () {
    return httpGetSimple(semaphoreGroupUrl, (resText) => __async(this, null, function* () {
      return {
        value: JSON.parse(resText),
        success: true
      };
    }));
  });
}

// src/SerializedPCDIntegration.ts
var import_react3 = require("react");
function useSerializedPCD(proofPackage, serializedPCD) {
  const [pcd, setPCD] = (0, import_react3.useState)();
  (0, import_react3.useEffect)(() => {
    if (serializedPCD) {
      const parsedPCD = JSON.parse(decodeURIComponent(serializedPCD));
      if (parsedPCD.type !== proofPackage.name) {
        return;
      }
      proofPackage.deserialize(parsedPCD.pcd).then((pcd2) => {
        setPCD(pcd2);
      });
    }
  }, [proofPackage, serializedPCD, setPCD]);
  return pcd;
}

// src/SemaphoreGroupIntegration.ts
function openGroupMembershipPopup(urlToZupassClient, popupUrl, urlToSemaphoreGroup, originalSiteName, signal, externalNullifier) {
  const proofUrl = constructZupassPcdGetRequestUrl(
    urlToZupassClient,
    popupUrl,
    import_semaphore_group_pcd.SemaphoreGroupPCDPackage.name,
    {
      externalNullifier: {
        argumentType: import_pcd_types3.ArgumentTypeName.BigInt,
        userProvided: false,
        value: externalNullifier != null ? externalNullifier : (0, import_util5.generateSnarkMessageHash)(originalSiteName).toString()
      },
      group: {
        argumentType: import_pcd_types3.ArgumentTypeName.Object,
        userProvided: false,
        remoteUrl: urlToSemaphoreGroup
      },
      identity: {
        argumentType: import_pcd_types3.ArgumentTypeName.PCD,
        pcdType: import_semaphore_identity_pcd3.SemaphoreIdentityPCDPackage.name,
        value: void 0,
        userProvided: true
      },
      signal: {
        argumentType: import_pcd_types3.ArgumentTypeName.BigInt,
        userProvided: false,
        value: signal != null ? signal : "1"
      }
    },
    {
      title: "Zuzalu Anon Auth",
      description: originalSiteName
    }
  );
  openZupassPopup(popupUrl, proofUrl);
}
function useSemaphoreGroupProof(pcdStr, semaphoreGroupUrl, originalSiteName, onVerified, externalNullifier) {
  const semaphoreGroupPCD = useSerializedPCD(import_semaphore_group_pcd.SemaphoreGroupPCDPackage, pcdStr);
  const [error, setError] = (0, import_react4.useState)();
  const [semaphoreGroup, setGroup] = (0, import_react4.useState)();
  const loadSemaphoreGroup = (0, import_react4.useCallback)(() => __async(this, null, function* () {
    if (!semaphoreGroupPCD)
      return;
    const groupResult = yield requestSemaphoreGroup(semaphoreGroupUrl);
    if (groupResult.success) {
      setGroup(groupResult.value);
    } else {
      setError(groupResult.error);
    }
  }), [semaphoreGroupPCD, semaphoreGroupUrl]);
  (0, import_react4.useEffect)(() => {
    loadSemaphoreGroup();
  }, [loadSemaphoreGroup]);
  (0, import_react4.useEffect)(() => {
    if (semaphoreGroupPCD && semaphoreGroup) {
      const proofExternalNullifier = externalNullifier != null ? externalNullifier : (0, import_util5.generateSnarkMessageHash)(originalSiteName).toString();
      verifyProof(
        semaphoreGroupPCD,
        semaphoreGroup,
        proofExternalNullifier
      ).then(onVerified);
    }
  }, [
    semaphoreGroupPCD,
    semaphoreGroup,
    externalNullifier,
    originalSiteName,
    onVerified
  ]);
  return {
    proof: semaphoreGroupPCD,
    group: semaphoreGroup,
    error
  };
}
function verifyProof(pcd, serializedExpectedGroup, externalNullifier) {
  return __async(this, null, function* () {
    const { verify } = import_semaphore_group_pcd.SemaphoreGroupPCDPackage;
    const verified = yield verify(pcd);
    if (!verified)
      return false;
    const sameExternalNullifier = pcd.claim.externalNullifier === externalNullifier;
    const expectedGroup = (0, import_semaphore_group_pcd.deserializeSemaphoreGroup)(serializedExpectedGroup);
    const sameRoot = expectedGroup.root.toString() === pcd.claim.merkleRoot;
    return sameExternalNullifier && sameRoot;
  });
}

// src/SemaphoreSignatureIntegration.ts
var import_pcd_types4 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd4 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd4 = require("@pcd/semaphore-signature-pcd");
var import_react5 = require("react");
function openSemaphoreSignaturePopup(urlToZupassClient, popupUrl, messageToSign, proveOnServer) {
  const proofUrl = constructZupassPcdGetRequestUrl(
    urlToZupassClient,
    popupUrl,
    import_semaphore_signature_pcd4.SemaphoreSignaturePCDPackage.name,
    {
      identity: {
        argumentType: import_pcd_types4.ArgumentTypeName.PCD,
        pcdType: import_semaphore_identity_pcd4.SemaphoreIdentityPCDPackage.name,
        value: void 0,
        userProvided: true
      },
      signedMessage: {
        argumentType: import_pcd_types4.ArgumentTypeName.String,
        value: messageToSign,
        userProvided: false
      }
    },
    {
      proveOnServer
    }
  );
  openZupassPopup(popupUrl, proofUrl);
}
function openSignedZuzaluUUIDPopup(urlToZupassClient, popupUrl, originalSiteName) {
  const proofUrl = constructZupassPcdGetRequestUrl(
    urlToZupassClient,
    popupUrl,
    import_semaphore_signature_pcd4.SemaphoreSignaturePCDPackage.name,
    {
      identity: {
        argumentType: import_pcd_types4.ArgumentTypeName.PCD,
        pcdType: import_semaphore_identity_pcd4.SemaphoreIdentityPCDPackage.name,
        value: void 0,
        userProvided: true
      },
      signedMessage: {
        argumentType: import_pcd_types4.ArgumentTypeName.String,
        userProvided: true,
        value: void 0
      }
    },
    {
      title: "Zuzalu Auth",
      description: originalSiteName
    }
  );
  openZupassPopup(popupUrl, proofUrl);
}
function openSignedZuzaluSignInPopup(zupassClientUrl, popupUrl, originalSiteName) {
  const proofUrl = constructZupassPcdGetRequestUrl(
    zupassClientUrl,
    popupUrl,
    import_semaphore_signature_pcd4.SemaphoreSignaturePCDPackage.name,
    {
      identity: {
        argumentType: import_pcd_types4.ArgumentTypeName.PCD,
        pcdType: import_semaphore_identity_pcd4.SemaphoreIdentityPCDPackage.name,
        value: void 0,
        userProvided: true
      },
      signedMessage: {
        argumentType: import_pcd_types4.ArgumentTypeName.String
      }
    },
    {
      title: "Zuzalu Auth",
      description: originalSiteName,
      signIn: true
    }
  );
  openZupassPopup(popupUrl, proofUrl);
}
function useSemaphoreSignatureProof(pcdStr, onVerified) {
  const semaphoreSignaturePCD = useSerializedPCD(
    import_semaphore_signature_pcd4.SemaphoreSignaturePCDPackage,
    pcdStr
  );
  (0, import_react5.useEffect)(() => {
    if (semaphoreSignaturePCD) {
      const { verify } = import_semaphore_signature_pcd4.SemaphoreSignaturePCDPackage;
      verify(semaphoreSignaturePCD).then(onVerified);
    }
  }, [semaphoreSignaturePCD, onVerified]);
  return {
    signatureProof: semaphoreSignaturePCD
  };
}

// src/Terms.ts
var MIN_PRIVACY_NOTICE = 0;
var LATEST_PRIVACY_NOTICE = 1;
var UNREDACT_TICKETS_TERMS_VERSION = 1;

// src/User.ts
var import_react6 = require("react");

// src/api/requestUser.ts
var import_url_join3 = __toESM(require("url-join"));
function requestUser(zupassServerUrl, uuid2) {
  return __async(this, null, function* () {
    return httpGet(
      (0, import_url_join3.default)(zupassServerUrl, "/account/user", uuid2),
      {
        onValue: (resText) => __async(this, null, function* () {
          return {
            value: JSON.parse(resText),
            success: true
          };
        }),
        onError: (resText, statusCode) => __async(this, null, function* () {
          return statusCode === 410 ? { error: { userMissing: true }, success: false } : { error: { errorMessage: resText }, success: false };
        })
      }
    );
  });
}

// src/User.ts
function useFetchUser(zupassServerUrl, isZuzalu, uuid2) {
  const [user, setUser] = (0, import_react6.useState)(null);
  const [error, setError] = (0, import_react6.useState)(null);
  const [loading, setLoading] = (0, import_react6.useState)(false);
  (0, import_react6.useEffect)(() => {
    const doLoad = () => __async(this, null, function* () {
      if (uuid2 == void 0) {
        setUser(null);
        setError(null);
        setLoading(false);
        return;
      }
      setLoading(true);
      const userResult = yield requestUser(zupassServerUrl, uuid2);
      setLoading(false);
      if (userResult.success) {
        setUser(userResult.value);
      } else {
        if (userResult.error.userMissing) {
          setError(`no user with id '${uuid2}'`);
        } else {
          setError(userResult.error.errorMessage);
        }
      }
    });
    doLoad();
  }, [isZuzalu, zupassServerUrl, uuid2]);
  return { user, error, loading };
}

// src/ZupassDefaultSubscriptions.ts
var import_pcd_collection3 = require("@pcd/pcd-collection");
var zupassDefaultSubscriptions = {
  ["4" /* Zuzalu_23 */]: {
    id: "4" /* Zuzalu_23 */,
    name: "Zuzalu tickets",
    description: "Your Zuzalu Tickets",
    partialArgs: void 0,
    credentialRequest: {
      signatureType: "sempahore-signature-pcd"
    },
    permissions: [
      {
        folder: "Zuzalu '23",
        type: import_pcd_collection3.PCDPermissionType.DeleteFolder
      },
      {
        folder: "Zuzalu '23",
        type: import_pcd_collection3.PCDPermissionType.ReplaceInFolder
      }
    ]
  },
  ["1" /* Devconnect */]: {
    id: "1" /* Devconnect */,
    name: "Devconnect Tickets",
    description: "Get your Devconnect tickets here!",
    partialArgs: void 0,
    credentialRequest: {
      signatureType: "sempahore-signature-pcd"
    },
    permissions: [
      {
        folder: "Devconnect",
        type: import_pcd_collection3.PCDPermissionType.AppendToFolder
      },
      {
        folder: "Devconnect",
        type: import_pcd_collection3.PCDPermissionType.ReplaceInFolder
      },
      {
        folder: "Devconnect",
        type: import_pcd_collection3.PCDPermissionType.DeleteFolder
      },
      {
        folder: "SBC SRW",
        type: import_pcd_collection3.PCDPermissionType.AppendToFolder
      },
      {
        folder: "SBC SRW",
        type: import_pcd_collection3.PCDPermissionType.ReplaceInFolder
      },
      {
        folder: "SBC SRW",
        type: import_pcd_collection3.PCDPermissionType.DeleteFolder
      }
    ]
  },
  ["3" /* Email */]: {
    id: "3" /* Email */,
    name: "Zupass Verified Emails",
    description: "Emails verified by Zupass",
    partialArgs: void 0,
    credentialRequest: {
      signatureType: "sempahore-signature-pcd"
    },
    permissions: [
      {
        folder: "Email",
        type: import_pcd_collection3.PCDPermissionType.DeleteFolder
      },
      {
        folder: "Email",
        type: import_pcd_collection3.PCDPermissionType.ReplaceInFolder
      }
    ]
  },
  ["5" /* Zuconnect_23 */]: {
    id: "5" /* Zuconnect_23 */,
    name: "Zuconnect tickets",
    description: "Your Zuconnect Tickets",
    partialArgs: void 0,
    credentialRequest: {
      signatureType: "sempahore-signature-pcd"
    },
    permissions: [
      {
        folder: "Zuconnect",
        type: import_pcd_collection3.PCDPermissionType.DeleteFolder
      },
      {
        folder: "ZuConnect",
        type: import_pcd_collection3.PCDPermissionType.DeleteFolder
      },
      {
        folder: "ZuConnect",
        type: import_pcd_collection3.PCDPermissionType.ReplaceInFolder
      }
    ]
  }
};

// src/api/apiResult.ts
function getNamedAPIErrorMessage(e) {
  if (e.detailedMessage) {
    return e.name + ": " + e.detailedMessage;
  } else {
    return e.name;
  }
}
var ERROR_NAME_UNKNOWN = "Unknown";
var ERROR_NAME_BAD_RESPONSE = "BadResponse";
function onNamedAPIError(resText, errorCode) {
  return __async(this, null, function* () {
    var _a;
    let apiError = {};
    let serverProvidedError = false;
    console.log(resText);
    try {
      const resJSON = JSON.parse(resText);
      if (((_a = resJSON.error) == null ? void 0 : _a.name) && typeof resJSON.error.name == "string") {
        serverProvidedError = true;
        apiError = resJSON.error;
        if ("detailedMessage" in apiError && typeof apiError.detailedMessage != "string") {
          delete apiError.detailedMessage;
        }
        if ("code" in apiError && typeof apiError.code != "number") {
          delete apiError.code;
        }
      } else {
        apiError.detailedMessage = resText;
      }
    } catch (e) {
    }
    if (!serverProvidedError) {
      apiError.detailedMessage = resText;
    }
    if (apiError.code === void 0 && errorCode !== void 0) {
      apiError.code = errorCode;
    }
    console.log(apiError);
    if (apiError.name === void 0 && apiError.code !== void 0) {
      apiError.name = apiErrorReasonFromCode(apiError.code);
    }
    if (apiError.name === void 0) {
      apiError.name = ERROR_NAME_UNKNOWN;
    }
    console.log(apiError);
    return { success: false, error: apiError };
  });
}
function apiErrorReasonFromCode(code) {
  switch (code) {
    case 400:
      return "BadRequest";
    case 401:
      return "Unauthorized";
    case 403:
      return "Forbidden";
    case 404:
      return "NotFound";
    case 409:
      return "Conflict";
    case 410:
      return "Gone";
    case 500:
      return "InternalServerError";
    case 501:
      return "NotImplemented";
    case 503:
      return "ServiceUnavailable";
    default:
      return void 0;
  }
}

// src/api/requestAgreeTerms.ts
var import_pcd_types5 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd5 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd5 = require("@pcd/semaphore-signature-pcd");
function requestAgreeTerms(passportServerUrl, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      `${passportServerUrl}/account/agree-terms`,
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}
function agreeTerms(zupassServerUrl, version, identity) {
  return __async(this, null, function* () {
    return requestAgreeTerms(zupassServerUrl, {
      // A generic authenticated route solution might make this much simpler
      pcd: yield import_semaphore_signature_pcd5.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd5.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types5.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd5.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd5.SemaphoreIdentityPCDPackage.prove({
                identity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types5.ArgumentTypeName.String,
            value: JSON.stringify({ version })
          }
        })
      )
    });
  });
}

// src/api/requestChangeBlobKey.ts
var import_url_join4 = __toESM(require("url-join"));
function requestChangeBlobKey(zupassServerUrl, oldBlobKey, newBlobKey, uuid2, newSalt, encryptedStorage, knownRevision) {
  return __async(this, null, function* () {
    return httpPost(
      (0, import_url_join4.default)(zupassServerUrl, `/sync/v2/changeBlobKey`),
      {
        onValue: (resText) => __async(this, null, function* () {
          return {
            value: JSON.parse(resText),
            success: true
          };
        }),
        onError: onNamedAPIError
      },
      {
        oldBlobKey,
        newBlobKey,
        newSalt,
        encryptedBlob: JSON.stringify(encryptedStorage),
        uuid: uuid2,
        knownRevision
      }
    );
  });
}

// src/api/requestCheckInById.ts
var import_pcd_types6 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd6 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd6 = require("@pcd/semaphore-signature-pcd");
var import_url_join5 = __toESM(require("url-join"));
function requestCheckInById(passportServerUrl, postBody) {
  return __async(this, null, function* () {
    return httpPost(
      (0, import_url_join5.default)(passportServerUrl, "/issue/check-in-by-id"),
      {
        // @todo - here and elsewhere - how can we do better than casting, and actually
        // make sure that the response we're getting back is the right shape?
        onValue: (resText) => __async(this, null, function* () {
          return JSON.parse(resText);
        }),
        onError: () => __async(this, null, function* () {
          return { error: { name: "ServerError" }, success: false };
        })
      },
      postBody
    );
  });
}
function checkinTicketById(passportServer, ticketId, checkerIdentity) {
  return __async(this, null, function* () {
    return requestCheckInById(passportServer, {
      ticketId,
      checkerProof: yield import_semaphore_signature_pcd6.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd6.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types6.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd6.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd6.SemaphoreIdentityPCDPackage.prove({
                identity: checkerIdentity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types6.ArgumentTypeName.String,
            value: ISSUANCE_STRING
          }
        })
      )
    });
  });
}

// src/api/requestCheckTicketById.ts
var import_pcd_types7 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd7 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd7 = require("@pcd/semaphore-signature-pcd");
var import_url_join6 = __toESM(require("url-join"));
function requestCheckTicketById(passportServerUrl, postBody) {
  return __async(this, null, function* () {
    return httpPost(
      (0, import_url_join6.default)(passportServerUrl, "/issue/check-ticket-by-id"),
      {
        onValue: (resText) => __async(this, null, function* () {
          return JSON.parse(resText);
        }),
        onError: () => __async(this, null, function* () {
          return {
            error: { name: "ServerError" },
            success: false
          };
        })
      },
      postBody
    );
  });
}
function checkTicketById(passportServer, ticketId, checkerIdentity) {
  return __async(this, null, function* () {
    return requestCheckTicketById(passportServer, {
      ticketId,
      signature: yield import_semaphore_signature_pcd7.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd7.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types7.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd7.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd7.SemaphoreIdentityPCDPackage.prove({
                identity: checkerIdentity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types7.ArgumentTypeName.String,
            value: ISSUANCE_STRING
          }
        })
      )
    });
  });
}

// src/api/requestConfirmationEmail.ts
var import_url_join7 = __toESM(require("url-join"));
function requestConfirmationEmail(zupassServerUrl, email, commitment, force) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join7.default)(zupassServerUrl, "/account/send-login-email"),
      (resText) => __async(this, null, function* () {
        return resText === "OK" ? { value: void 0, success: true } : {
          value: JSON.parse(resText),
          success: true
        };
      }),
      {
        email,
        commitment,
        force: force ? "true" : "false"
      }
    );
  });
}

// src/api/requestCreateNewUser.ts
var import_url_join8 = __toESM(require("url-join"));
function requestCreateNewUser(zupassServerUrl, email, token, commitment, salt, encryptionKey) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join8.default)(zupassServerUrl, "/account/new-participant"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      {
        email,
        token,
        commitment,
        salt,
        encryptionKey
      }
    );
  });
}

// src/api/requestDownloadAndDecryptStorage.ts
var import_passport_crypto3 = require("@pcd/passport-crypto");
var import_util6 = require("@pcd/util");

// src/api/requestEncryptedStorage.ts
var import_url_join9 = __toESM(require("url-join"));
function requestEncryptedStorage(zupassServerUrl, blobKey, knownRevision) {
  return __async(this, null, function* () {
    return httpGet(
      (0, import_url_join9.default)(zupassServerUrl, "/sync/v2/load"),
      {
        onValue: (resText) => __async(this, null, function* () {
          return {
            value: JSON.parse(resText),
            success: true
          };
        }),
        onError: onNamedAPIError
      },
      { blobKey, knownRevision }
    );
  });
}

// src/api/requestDownloadAndDecryptStorage.ts
function requestDownloadAndDecryptStorage(zupassServerUrl, encryptionKey) {
  return __async(this, null, function* () {
    const result = yield requestDownloadAndDecryptUpdatedStorage(
      zupassServerUrl,
      encryptionKey,
      void 0
    );
    if (!result.success) {
      return { error: result.error, success: false };
    }
    if (!result.value.storage) {
      console.error("unexpectedly missing e2ee data");
      return {
        error: {
          name: ERROR_NAME_BAD_RESPONSE,
          detailedMessage: "unexpectedly missing e2ee data"
        },
        success: false
      };
    }
    return {
      value: { storage: result.value.storage, revision: result.value.revision },
      success: true
    };
  });
}
function requestDownloadAndDecryptUpdatedStorage(zupassServerUrl, encryptionKey, knownRevision) {
  return __async(this, null, function* () {
    try {
      const encryptionKeyHash = yield (0, import_passport_crypto3.getHash)(encryptionKey);
      const storageResult = yield requestEncryptedStorage(
        zupassServerUrl,
        encryptionKeyHash,
        knownRevision
      );
      if (!storageResult.success) {
        console.error(`error loading e2ee data`, storageResult.error);
        return storageResult;
      }
      if (!storageResult.value.encryptedBlob) {
        if (!knownRevision) {
          console.error("missing e2ee data when downloading without revision");
          return {
            error: {
              name: ERROR_NAME_BAD_RESPONSE,
              detailedMessage: "missing e2ee data when downloading without revision"
            },
            success: false
          };
        }
        return {
          value: { revision: storageResult.value.revision },
          success: true
        };
      }
      const encryptedStorage = JSON.parse(
        storageResult.value.encryptedBlob
      );
      const decrypted = yield (0, import_passport_crypto3.passportDecrypt)(encryptedStorage, encryptionKey);
      return {
        value: {
          storage: JSON.parse(decrypted),
          revision: storageResult.value.revision
        },
        success: true
      };
    } catch (e) {
      console.error(`error loading e2ee data`, e);
      return {
        error: { name: ERROR_NAME_UNKNOWN, detailedMessage: (0, import_util6.getErrorMessage)(e) },
        success: false
      };
    }
  });
}

// src/api/requestFrogCryptoDeleteFrogs.ts
var import_url_join10 = __toESM(require("url-join"));
function requestFrogCryptoDeleteFrogs(zupassServerUrl, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join10.default)(zupassServerUrl, "/frogcrypto/admin/delete-frogs"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}

// src/api/requestFrogCryptoGetScoreboard.ts
var import_url_join11 = __toESM(require("url-join"));
function requestFrogCryptoGetScoreboard(zupassServerUrl) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join11.default)(zupassServerUrl, "/frogcrypto/scoreboard"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      })
    );
  });
}

// src/api/requestFrogCryptoGetUserState.ts
var import_pcd_types8 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd8 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd8 = require("@pcd/semaphore-signature-pcd");
var import_url_join12 = __toESM(require("url-join"));
function requestFrogCryptoGetUserState(zupassServerUrl, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join12.default)(zupassServerUrl, "/frogcrypto/user-state"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}
function frogCryptoGetUserState(zupassServerUrl, identity, signedMessage, feedIds) {
  return __async(this, null, function* () {
    return requestFrogCryptoGetUserState(zupassServerUrl, {
      pcd: yield import_semaphore_signature_pcd8.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd8.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types8.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd8.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd8.SemaphoreIdentityPCDPackage.prove({
                identity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types8.ArgumentTypeName.String,
            value: signedMessage
          }
        })
      ),
      feedIds
    });
  });
}

// src/api/requestFrogCryptoUpdateFeeds.ts
var import_url_join13 = __toESM(require("url-join"));
function requestFrogCryptoUpdateFeeds(zupassServerUrl, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join13.default)(zupassServerUrl, "/frogcrypto/admin/feeds"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}

// src/api/requestFrogCryptoUpdateFrogs.ts
var import_url_join14 = __toESM(require("url-join"));
function requestFrogCryptoUpdateFrogs(zupassServerUrl, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join14.default)(zupassServerUrl, "/frogcrypto/admin/frogs"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}

// src/api/requestFrogCryptoUpdateTelegramSharing.ts
var import_pcd_types9 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd9 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd9 = require("@pcd/semaphore-signature-pcd");
var import_url_join15 = __toESM(require("url-join"));
function requestFrogCryptoUpdateTelegramHandleSharing(zupassServerUrl, req) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join15.default)(zupassServerUrl, "/frogcrypto/telegram-handle-sharing"),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      req
    );
  });
}
function frogCryptoUpdateTelegramHandleSharing(zupassServerUrl, identity, signedMessage, reveal) {
  return __async(this, null, function* () {
    return requestFrogCryptoUpdateTelegramHandleSharing(zupassServerUrl, {
      pcd: yield import_semaphore_signature_pcd9.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd9.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types9.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd9.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd9.SemaphoreIdentityPCDPackage.prove({
                identity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types9.ArgumentTypeName.String,
            value: signedMessage
          }
        })
      ),
      reveal
    });
  });
}

// src/api/requestIssuanceServiceEnabled.ts
var import_url_join16 = __toESM(require("url-join"));
function requestIssuanceServiceEnabled(zupassServerUrl) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join16.default)(zupassServerUrl, "/issue/enabled"),
      (resText) => __async(this, null, function* () {
        return { value: resText === "true", success: true };
      })
    );
  });
}

// src/api/requestKnownTickets.ts
var import_url_join17 = __toESM(require("url-join"));
function requestKnownTicketTypes(zupassServerUrl) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join17.default)(zupassServerUrl, `/issue/known-ticket-types`),
      (resText) => __async(this, null, function* () {
        return JSON.parse(resText);
      })
    );
  });
}

// src/api/requestLogToServer.ts
var import_url_join18 = __toESM(require("url-join"));
function requestLogToServer(zupassServerUrl, eventName, value) {
  return __async(this, null, function* () {
    try {
      yield fetch((0, import_url_join18.default)(zupassServerUrl, "client-log"), __spreadProps(__spreadValues({}, POST), {
        body: JSON.stringify(__spreadValues({ name: eventName }, value))
      }));
    } catch (e) {
      console.log("failed to log event to server", e);
    }
  });
}

// src/api/requestOfflineTickets.ts
var import_pcd_types10 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd10 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd10 = require("@pcd/semaphore-signature-pcd");
var import_url_join19 = __toESM(require("url-join"));
function requestOfflineTickets(passportServerUrl, postBody) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join19.default)(passportServerUrl, "/issue/offline-tickets"),
      (resText) => __async(this, null, function* () {
        return {
          success: true,
          value: JSON.parse(resText)
        };
      }),
      postBody
    );
  });
}
function offlineTickets(passportServer, checkerIdentity) {
  return __async(this, null, function* () {
    return requestOfflineTickets(passportServer, {
      checkerProof: yield import_semaphore_signature_pcd10.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd10.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types10.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd10.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd10.SemaphoreIdentityPCDPackage.prove({
                identity: checkerIdentity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types10.ArgumentTypeName.String,
            value: ISSUANCE_STRING
          }
        })
      )
    });
  });
}

// src/api/requestOfflineTicketsCheckin.ts
var import_pcd_types11 = require("@pcd/pcd-types");
var import_semaphore_identity_pcd11 = require("@pcd/semaphore-identity-pcd");
var import_semaphore_signature_pcd11 = require("@pcd/semaphore-signature-pcd");
var import_url_join20 = __toESM(require("url-join"));
function requestOfflineTicketsCheckin(passportServerUrl, postBody) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join20.default)(passportServerUrl, "/issue/checkin-offline-tickets"),
      (resText) => __async(this, null, function* () {
        return {
          success: true,
          value: JSON.parse(resText)
        };
      }),
      postBody
    );
  });
}
function offlineTicketsCheckin(passportServer, checkerIdentity, checkedOfflineInDevconnectTickets) {
  return __async(this, null, function* () {
    return requestOfflineTicketsCheckin(passportServer, {
      checkedOfflineInDevconnectTicketIDs: checkedOfflineInDevconnectTickets.map(
        (t) => t.id
      ),
      checkerProof: yield import_semaphore_signature_pcd11.SemaphoreSignaturePCDPackage.serialize(
        yield import_semaphore_signature_pcd11.SemaphoreSignaturePCDPackage.prove({
          identity: {
            argumentType: import_pcd_types11.ArgumentTypeName.PCD,
            value: yield import_semaphore_identity_pcd11.SemaphoreIdentityPCDPackage.serialize(
              yield import_semaphore_identity_pcd11.SemaphoreIdentityPCDPackage.prove({
                identity: checkerIdentity
              })
            )
          },
          signedMessage: {
            argumentType: import_pcd_types11.ArgumentTypeName.String,
            value: ISSUANCE_STRING
          }
        })
      )
    });
  });
}

// src/api/requestPasswordSalt.ts
var import_url_join21 = __toESM(require("url-join"));
function requestPasswordSalt(zupassServerUrl, email) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join21.default)(
        zupassServerUrl,
        `/account/salt?${new URLSearchParams({
          email
        }).toString()}`
      ),
      (resText) => __async(this, null, function* () {
        return {
          value: resText,
          success: true
        };
      })
    );
  });
}

// src/api/requestPretixSyncStatus.ts
var import_url_join22 = __toESM(require("url-join"));
function requestPretixSyncStatus(zupassServerUrl, isZuzalu) {
  return __async(this, null, function* () {
    return httpGetSimple(
      isZuzalu ? (0, import_url_join22.default)(zupassServerUrl, `/pretix/status`) : (0, import_url_join22.default)(zupassServerUrl, `/devconnect-pretix/status`),
      (resText) => __async(this, null, function* () {
        return { value: resText, success: true };
      })
    );
  });
}

// src/api/requestProveOnServer.ts
var import_url_join23 = __toESM(require("url-join"));
function requestProveOnServer(zupassServerUrl, serverReq) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join23.default)(zupassServerUrl, `/pcds/prove`),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      serverReq
    );
  });
}

// src/api/requestServerEdDSAPublicKey.ts
var import_url_join24 = __toESM(require("url-join"));
function requestServerEdDSAPublicKey(zupassServerUrl) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join24.default)(zupassServerUrl, `/issue/eddsa-public-key`),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      })
    );
  });
}

// src/api/requestServerRSAPublicKey.ts
var import_url_join25 = __toESM(require("url-join"));
function requestServerRSAPublicKey(zupassServerUrl) {
  return __async(this, null, function* () {
    return httpGetSimple(
      (0, import_url_join25.default)(zupassServerUrl, `/issue/rsa-public-key`),
      (resText) => __async(this, null, function* () {
        return { value: resText, success: true };
      })
    );
  });
}

// src/api/requestUploadEncryptedStorage.ts
var import_url_join26 = __toESM(require("url-join"));
function requestUploadEncryptedStorage(zupassServerUrl, blobKey, encryptedStorage, knownRevision) {
  return __async(this, null, function* () {
    return httpPost(
      (0, import_url_join26.default)(zupassServerUrl, `/sync/v2/save`),
      {
        onValue: (resText) => __async(this, null, function* () {
          return {
            value: JSON.parse(resText),
            success: true
          };
        }),
        onError: onNamedAPIError
      },
      {
        blobKey,
        encryptedBlob: JSON.stringify(encryptedStorage),
        knownRevision
      }
    );
  });
}

// src/api/requestVerifyTicket.ts
var import_url_join27 = __toESM(require("url-join"));
function requestVerifyTicket(passportServerUrl, postBody) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join27.default)(passportServerUrl, "/issue/verify-ticket"),
      (resText) => __async(this, null, function* () {
        return JSON.parse(resText);
      }),
      postBody
    );
  });
}

// src/api/requestVerifyTicketById.ts
var import_url_join28 = __toESM(require("url-join"));
function requestVerifyTicketById(passportServerUrl, postBody) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join28.default)(passportServerUrl, "/issue/verify-ticket-by-id"),
      (resText) => __async(this, null, function* () {
        return JSON.parse(resText);
      }),
      postBody
    );
  });
}

// src/api/requestVerifyToken.ts
var import_url_join29 = __toESM(require("url-join"));
function requestVerifyToken(zupassServerUrl, email, token) {
  return __async(this, null, function* () {
    return httpPostSimple(
      (0, import_url_join29.default)(zupassServerUrl, `/account/verify-token`),
      (resText) => __async(this, null, function* () {
        return {
          value: JSON.parse(resText),
          success: true
        };
      }),
      { email, token }
    );
  });
}

// src/zuzalu.ts
var ZuzaluUserRole = /* @__PURE__ */ ((ZuzaluUserRole2) => {
  ZuzaluUserRole2["Visitor"] = "visitor";
  ZuzaluUserRole2["Resident"] = "resident";
  ZuzaluUserRole2["Organizer"] = "organizer";
  return ZuzaluUserRole2;
})(ZuzaluUserRole || {});
var ZUZALU_23_RESIDENT_PRODUCT_ID = "5ba4cd9e-893c-4a4a-b15b-cf36ceda1938";
var ZUZALU_23_VISITOR_PRODUCT_ID = "53b518ed-e427-4a23-bf36-a6e1e2764256";
var ZUZALU_23_ORGANIZER_PRODUCT_ID = "10016d35-40df-4033-a171-7d661ebaccaa";
var ZUZALU_23_EVENT_ID = "5de90d09-22db-40ca-b3ae-d934573def8b";

// src/zuconnect.ts
var ZUCONNECT_TICKET_NAMES = [
  "ZuConnect Resident Pass",
  "1st Week Pass",
  "ZuConnect Scholarship",
  "ZuConnect Organizer Pass",
  "For people only using Day Passes (add-ons)",
  "Latecomer Pass"
];
var ZUCONNECT_23_RESIDENT_EVENT_ID = "91312aa1-5f74-4264-bdeb-f4a3ddb8670c";
var ZUCONNECT_23_FIRST_WEEK_EVENT_ID = "54863995-10c4-46e4-9342-75e48b68d307";
var ZUCONNECT_23_SCHOLARSHIP_EVENT_ID = "797de414-2aec-4ef8-8655-09df7e2b6cc6";
var ZUCONNECT_23_ORGANIZER_EVENT_ID = "f7370f63-b9ae-480c-9ded-0663f1922bef";
var ZUCONNECT_23_DAY_PASS_EVENT_ID = "a6109324-7ca0-4198-9583-77962d1b9d53";
var ZUCONNECT_23_RESIDENT_PRODUCT_ID = "cc9e3650-c29b-4629-b275-6b34fc70b2f9";
var ZUCONNECT_23_FIRST_WEEK_PRODUCT_ID = "d2123bf9-c027-4851-b52c-d8b73fc3f5af";
var ZUCONNECT_23_SCHOLARSHIP_PRODUCT_ID = "d3620f38-56a9-4235-bea8-0d1dba6bb623";
var ZUCONNECT_23_ORGANIZER_PRODUCT_ID = "0179ed5b-f265-417c-aeaa-ac61a525c6b0";
var ZUCONNECT_23_DAY_PASS_PRODUCT_ID = "98437d28-0a39-4f40-9f2a-b38bf04cb55d";
var ZUCONNECT_PRODUCT_ID_MAPPINGS = {
  "ZuConnect Resident Pass": {
    id: ZUCONNECT_23_RESIDENT_PRODUCT_ID,
    eventId: ZUCONNECT_23_RESIDENT_EVENT_ID,
    name: "Resident",
    zuzaluRoleEquivalent: "resident" /* Resident */
  },
  "ZuConnect Scholarship": {
    id: ZUCONNECT_23_SCHOLARSHIP_PRODUCT_ID,
    eventId: ZUCONNECT_23_SCHOLARSHIP_EVENT_ID,
    name: "Resident",
    zuzaluRoleEquivalent: "resident" /* Resident */
  },
  "1st Week Pass": {
    id: ZUCONNECT_23_FIRST_WEEK_PRODUCT_ID,
    eventId: ZUCONNECT_23_FIRST_WEEK_EVENT_ID,
    name: "Resident",
    zuzaluRoleEquivalent: "resident" /* Resident */
  },
  "ZuConnect Organizer Pass": {
    id: ZUCONNECT_23_ORGANIZER_PRODUCT_ID,
    eventId: ZUCONNECT_23_ORGANIZER_EVENT_ID,
    name: "Organizer",
    zuzaluRoleEquivalent: "organizer" /* Organizer */
  },
  "For people only using Day Passes (add-ons)": {
    id: ZUCONNECT_23_DAY_PASS_PRODUCT_ID,
    eventId: ZUCONNECT_23_DAY_PASS_EVENT_ID,
    name: "Visitor",
    zuzaluRoleEquivalent: "visitor" /* Visitor */
  },
  "Latecomer Pass": {
    id: ZUCONNECT_23_RESIDENT_PRODUCT_ID,
    eventId: ZUCONNECT_23_RESIDENT_EVENT_ID,
    name: "Resident",
    zuzaluRoleEquivalent: "resident" /* Resident */
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  CredentialManager,
  ERROR_NAME_BAD_RESPONSE,
  ERROR_NAME_UNKNOWN,
  FROG_FREEROLLS,
  FROG_SCORE_CAP,
  FeedHost,
  FeedSubscriptionManager,
  FrogCryptoDbFeedDataSchema,
  FrogCryptoFeedBiomeConfigSchema,
  FrogCryptoFeedBiomeConfigsSchema,
  FrogCryptoFolderName,
  FrogCryptoFrogDataSchema,
  IFrogCryptoClientFeedSchema,
  IFrogCryptoFeedSchema,
  ISSUANCE_STRING,
  KnownPublicKeyType,
  KnownTicketGroup,
  LATEST_PRIVACY_NOTICE,
  MIN_PRIVACY_NOTICE,
  NetworkFeedApi,
  PCDRequestType,
  POST,
  PayloadType,
  PendingPCDStatus,
  SubscriptionErrorType,
  UNREDACT_TICKETS_TERMS_VERSION,
  ZUCONNECT_23_DAY_PASS_EVENT_ID,
  ZUCONNECT_23_DAY_PASS_PRODUCT_ID,
  ZUCONNECT_23_FIRST_WEEK_EVENT_ID,
  ZUCONNECT_23_FIRST_WEEK_PRODUCT_ID,
  ZUCONNECT_23_ORGANIZER_EVENT_ID,
  ZUCONNECT_23_ORGANIZER_PRODUCT_ID,
  ZUCONNECT_23_RESIDENT_EVENT_ID,
  ZUCONNECT_23_RESIDENT_PRODUCT_ID,
  ZUCONNECT_23_SCHOLARSHIP_EVENT_ID,
  ZUCONNECT_23_SCHOLARSHIP_PRODUCT_ID,
  ZUCONNECT_PRODUCT_ID_MAPPINGS,
  ZUCONNECT_TICKET_NAMES,
  ZUZALU_23_EVENT_ID,
  ZUZALU_23_ORGANIZER_PRODUCT_ID,
  ZUZALU_23_RESIDENT_PRODUCT_ID,
  ZUZALU_23_VISITOR_PRODUCT_ID,
  ZupassFeedIds,
  ZuzaluUserRole,
  agreeTerms,
  apiErrorReasonFromCode,
  applyActions,
  checkTicketById,
  checkinTicketById,
  constructZupassPcdAddRequestUrl,
  constructZupassPcdGetRequestUrl,
  constructZupassPcdProveAndAddRequestUrl,
  createCredentialCache,
  createFeedCredentialPayload,
  createStorageBackedCredentialCache,
  defaultOfflineTickets,
  deserializeStorage,
  frogCryptoGetUserState,
  frogCryptoUpdateTelegramHandleSharing,
  getNamedAPIErrorMessage,
  getStorageHash,
  getWithoutProvingUrl,
  hashProveRequest,
  httpGet,
  httpGetSimple,
  httpPost,
  httpPostSimple,
  isSettledPendingPCDStatus,
  isSyncedEncryptedStorageV1,
  isSyncedEncryptedStorageV2,
  isSyncedEncryptedStorageV3,
  mapStringError,
  offlineTickets,
  offlineTicketsCheckin,
  onNamedAPIError,
  openGroupMembershipPopup,
  openSemaphoreSignaturePopup,
  openSignedZuzaluSignInPopup,
  openSignedZuzaluUUIDPopup,
  openZupassPopup,
  pollFeed,
  requestAgreeTerms,
  requestChangeBlobKey,
  requestCheckInById,
  requestCheckTicketById,
  requestConfirmationEmail,
  requestCreateNewUser,
  requestDownloadAndDecryptStorage,
  requestDownloadAndDecryptUpdatedStorage,
  requestEncryptedStorage,
  requestFrogCryptoDeleteFrogs,
  requestFrogCryptoGetScoreboard,
  requestFrogCryptoGetUserState,
  requestFrogCryptoUpdateFeeds,
  requestFrogCryptoUpdateFrogs,
  requestFrogCryptoUpdateTelegramHandleSharing,
  requestIssuanceServiceEnabled,
  requestKnownTicketTypes,
  requestListFeeds,
  requestLogToServer,
  requestOfflineTickets,
  requestOfflineTicketsCheckin,
  requestPasswordSalt,
  requestPollFeed,
  requestPretixSyncStatus,
  requestProveOnServer,
  requestSemaphoreGroup,
  requestServerEdDSAPublicKey,
  requestServerProofStatus,
  requestServerRSAPublicKey,
  requestUploadEncryptedStorage,
  requestUser,
  requestVerifyTicket,
  requestVerifyTicketById,
  requestVerifyToken,
  serializeStorage,
  useFetchUser,
  usePCDMultiplexer,
  usePendingPCD,
  useSemaphoreGroupProof,
  useSemaphoreSignatureProof,
  useSerializedPCD,
  useZupassPopupMessages,
  useZupassPopupSetup,
  verifyFeedCredential,
  zupassDefaultSubscriptions
});
