"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  PATH_SEP: () => PATH_SEP,
  PCDActionType: () => PCDActionType,
  PCDCollection: () => PCDCollection,
  PCDPermissionType: () => PCDPermissionType,
  escapePathSegment: () => escapePathSegment,
  getAllAncestors: () => getAllAncestors,
  getFoldersInFolder: () => getFoldersInFolder,
  getNameFromPath: () => getNameFromPath,
  getParentFolder: () => getParentFolder,
  isAppendToFolderAction: () => isAppendToFolderAction,
  isAppendToFolderPermission: () => isAppendToFolderPermission,
  isChild: () => isChild,
  isDeleteFolderAction: () => isDeleteFolderAction,
  isDeleteFolderPermission: () => isDeleteFolderPermission,
  isFolderAncestor: () => isFolderAncestor,
  isPCDFolderPermission: () => isPCDFolderPermission,
  isReplaceInFolderAction: () => isReplaceInFolderAction,
  isReplaceInFolderPermission: () => isReplaceInFolderPermission,
  isRootFolder: () => isRootFolder,
  joinPath: () => joinPath,
  matchActionToPermission: () => matchActionToPermission,
  normalizePath: () => normalizePath,
  splitPath: () => splitPath
});
module.exports = __toCommonJS(src_exports);

// src/PCDCollection.ts
var import_emitter = require("@pcd/emitter");
var import_passport_crypto = require("@pcd/passport-crypto");
var import_fast_json_stable_stringify = __toESM(require("fast-json-stable-stringify"));
var import_lodash2 = __toESM(require("lodash"));

// src/actions.ts
var PCDActionType = {
  ReplaceInFolder: "ReplaceInFolder_action",
  AppendToFolder: "AppendToFolder_action",
  DeleteFolder: "DeleteFolder_action"
};
function isReplaceInFolderAction(action) {
  return action.type === PCDActionType.ReplaceInFolder;
}
function isAppendToFolderAction(action) {
  return action.type === PCDActionType.AppendToFolder;
}
function isDeleteFolderAction(action) {
  return action.type === PCDActionType.DeleteFolder;
}

// src/permissions.ts
var PCDPermissionType = {
  ReplaceInFolder: "ReplaceInFolder_permission",
  AppendToFolder: "AppendToFolder_permission",
  DeleteFolder: "DeleteFolder_permission"
};
function isPCDFolderPermission(permission) {
  return [
    PCDPermissionType.AppendToFolder,
    PCDPermissionType.ReplaceInFolder,
    PCDPermissionType.DeleteFolder
  ].includes(permission.type);
}
function isAppendToFolderPermission(permission) {
  return permission.type === PCDPermissionType.AppendToFolder;
}
function isReplaceInFolderPermission(permission) {
  return permission.type === PCDPermissionType.ReplaceInFolder;
}
function isDeleteFolderPermission(permission) {
  return permission.type === PCDPermissionType.DeleteFolder;
}

// src/util.ts
var import_lodash = __toESM(require("lodash"));
var PATH_SEP = "/";
function getFoldersInFolder(folderPath, allPaths) {
  const descendantsOfFolder = import_lodash.default.uniq(
    allPaths.filter((p) => isFolderAncestor(p, folderPath))
  );
  const descendantsWithMissing = import_lodash.default.uniq([
    ...descendantsOfFolder.flatMap((path) => getAllAncestors(path)),
    ...descendantsOfFolder
  ]).filter((a) => a !== "");
  const directDescendants = descendantsWithMissing.filter(
    (d) => isChild(folderPath, d)
  );
  return directDescendants;
}
function getAllAncestors(path) {
  const parts = splitPath(path);
  const result = [];
  while (parts.length > 0) {
    parts.pop();
    result.push(parts.join(PATH_SEP));
  }
  return result;
}
function isChild(parent, child) {
  const normalizedPath = normalizePath(parent);
  const descendantParts = splitPath(child);
  descendantParts.pop();
  if (normalizedPath === descendantParts.join(PATH_SEP)) {
    return true;
  }
  return false;
}
function isFolderAncestor(possibleDescendant, possibleAncestor) {
  const pathParts = splitPath(possibleDescendant);
  const folderParts = splitPath(possibleAncestor);
  if (folderParts.length >= pathParts.length) {
    return false;
  }
  for (let i = 0; i < folderParts.length; i++) {
    if (folderParts[i] !== pathParts[i]) {
      return false;
    }
  }
  return true;
}
function splitPath(path) {
  return path.split(PATH_SEP).filter((p) => p !== "");
}
function joinPath(...segments) {
  return segments.map(escapePathSegment).join(PATH_SEP);
}
function normalizePath(path) {
  return splitPath(path).join(PATH_SEP);
}
function getParentFolder(folderPath) {
  const parts = splitPath(folderPath);
  parts.pop();
  return parts.join(PATH_SEP);
}
function isRootFolder(folderPath) {
  return normalizePath(folderPath) === "";
}
function getNameFromPath(path) {
  const parts = splitPath(path);
  if (parts.length === 0) {
    return "";
  }
  return parts[parts.length - 1];
}
function escapePathSegment(name) {
  return name.replace(/\//g, "");
}

// src/PCDCollection.ts
function matchActionToPermission(action, permissions) {
  for (const permission of permissions) {
    if (isAppendToFolderAction(action) && isAppendToFolderPermission(permission) && (action.folder === permission.folder || isFolderAncestor(action.folder, permission.folder))) {
      return { action, permission };
    }
    if (isReplaceInFolderAction(action) && isReplaceInFolderPermission(permission) && (action.folder === permission.folder || isFolderAncestor(action.folder, permission.folder))) {
      return { action, permission };
    }
    if (isDeleteFolderAction(action) && isDeleteFolderPermission(permission) && (action.folder === permission.folder || isFolderAncestor(action.folder, permission.folder))) {
      return { action, permission };
    }
  }
  return null;
}
var PCDCollection = class {
  // pcd id -> folder
  constructor(packages, pcds, folders) {
    this.packages = packages;
    this.pcds = pcds != null ? pcds : [];
    this.folders = folders != null ? folders : {};
    this.hashEmitter = new import_emitter.Emitter();
  }
  getFoldersInFolder(folderPath) {
    return getFoldersInFolder(folderPath, Object.values(this.folders));
  }
  isValidFolder(folderPath) {
    return Object.values(this.folders).includes(folderPath);
  }
  setPCDFolder(pcdId, folder) {
    if (!this.hasPCDWithId(pcdId)) {
      throw new Error(`can't set folder of pcd ${pcdId} - pcd doesn't exist`);
    }
    this.folders[pcdId] = folder;
    this.recalculateAndEmitHash();
  }
  tryExec(action, permissions) {
    return __async(this, null, function* () {
      const match = matchActionToPermission(action, permissions);
      if (!match) {
        return false;
      }
      try {
        const result = yield this.tryExecutingActionWithPermission(
          match.action,
          match.permission
        );
        if (result) {
          return true;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
      return false;
    });
  }
  tryExecutingActionWithPermission(action, permission) {
    return __async(this, null, function* () {
      if (isAppendToFolderAction(action) && isAppendToFolderPermission(permission)) {
        if (action.folder !== permission.folder && !isFolderAncestor(action.folder, permission.folder)) {
          return false;
        }
        const pcds = yield this.deserializeAll(action.pcds);
        for (const pcd of pcds) {
          if (this.hasPCDWithId(pcd.id)) {
            throw new Error(`pcd with ${pcd.id} already exists`);
          }
        }
        this.addAll(pcds);
        this.bulkSetFolder(
          pcds.map((pcd) => pcd.id),
          action.folder
        );
        return true;
      }
      if (isReplaceInFolderAction(action) && isReplaceInFolderPermission(permission)) {
        if (action.folder !== permission.folder && !isFolderAncestor(action.folder, permission.folder)) {
          return false;
        }
        const pcds = yield this.deserializeAll(action.pcds);
        for (const pcd of pcds) {
          if (this.hasPCDWithId(pcd.id) && this.getFolderOfPCD(pcd.id) !== action.folder) {
            throw new Error(
              `pcd with ${pcd.id} already exists outside the allowed folder`
            );
          }
        }
        this.addAll(pcds, { upsert: true });
        this.bulkSetFolder(
          pcds.map((pcd) => pcd.id),
          action.folder
        );
        return true;
      }
      if (isDeleteFolderAction(action) && isDeleteFolderPermission(permission)) {
        if (action.folder !== permission.folder && !isFolderAncestor(action.folder, permission.folder)) {
          return false;
        }
        this.deleteFolder(action.folder, action.recursive);
        return true;
      }
      return false;
    });
  }
  getSize() {
    return this.pcds.length;
  }
  getAllFolderNames() {
    const result = /* @__PURE__ */ new Set();
    Object.entries(this.folders).forEach(
      ([_pcdId, folder]) => result.add(folder)
    );
    return Array.from(result);
  }
  bulkSetFolder(pcdIds, folder) {
    pcdIds.forEach((pcdId) => {
      if (!this.hasPCDWithId(pcdId)) {
        throw new Error(`can't set folder of pcd ${pcdId} - pcd doesn't exist`);
      }
    });
    pcdIds.forEach((pcdId) => {
      this.folders[pcdId] = folder;
    });
    this.recalculateAndEmitHash();
  }
  setFolder(pcdId, folder) {
    this.bulkSetFolder([pcdId], folder);
  }
  getFolderOfPCD(pcdId) {
    var _a;
    if (!this.hasPCDWithId(pcdId)) {
      return void 0;
    }
    return (_a = Object.entries(this.folders).find(
      ([id, _folder]) => pcdId === id
    )) == null ? void 0 : _a[1];
  }
  getAllPCDsInFolder(folder) {
    if (isRootFolder(folder)) {
      const pcdIdsInFolders = /* @__PURE__ */ new Set([...Object.keys(this.folders)]);
      const pcdsNotInFolders = this.pcds.filter(
        (pcd) => !pcdIdsInFolders.has(pcd.id)
      );
      return pcdsNotInFolders;
    }
    const pcdIds = Object.entries(this.folders).filter(([_pcdId, f]) => f === folder).map(([pcdId, _f]) => pcdId);
    return this.getByIds(pcdIds);
  }
  removeAllPCDsInFolder(folder) {
    const inFolder = this.getAllPCDsInFolder(folder);
    inFolder.forEach((pcd) => this.remove(pcd.id));
  }
  replacePCDsInFolder(folder, pcds) {
    this.removeAllPCDsInFolder(folder);
    this.addAll(pcds, { upsert: true });
    pcds.forEach((pcd) => this.setPCDFolder(pcd.id, folder));
  }
  /**
   * Removes all PCDs within a given folder, and optionally within all
   * subfolders.
   */
  deleteFolder(folder, recursive) {
    const folders = [folder];
    if (recursive) {
      const subFolders = Object.values(this.folders).filter((folderPath) => {
        return isFolderAncestor(folderPath, folder);
      });
      folders.push(...import_lodash2.default.uniq(subFolders));
    }
    for (const folderPath of folders) {
      this.removeAllPCDsInFolder(folderPath);
    }
  }
  getPackage(name) {
    const matching = this.packages.find((p) => p.name === name);
    return matching;
  }
  hasPackage(name) {
    return this.packages.find((p) => p.name === name) !== void 0;
  }
  serialize(pcd) {
    return __async(this, null, function* () {
      const pcdPackage = this.getPackage(pcd.type);
      if (!pcdPackage)
        throw new Error(`no package matching ${pcd.type}`);
      const serialized = yield pcdPackage.serialize(pcd);
      return serialized;
    });
  }
  serializeAll() {
    return __async(this, null, function* () {
      return Promise.all(this.pcds.map(this.serialize.bind(this)));
    });
  }
  serializeCollection() {
    return __async(this, null, function* () {
      return (0, import_fast_json_stable_stringify.default)({
        pcds: yield Promise.all(this.pcds.map(this.serialize.bind(this))),
        folders: this.folders
      });
    });
  }
  deserialize(serialized) {
    return __async(this, null, function* () {
      const pcdPackage = this.getPackage(serialized.type);
      if (!pcdPackage)
        throw new Error(`no package matching ${serialized.type}`);
      const deserialized = yield pcdPackage.deserialize(serialized.pcd);
      return deserialized;
    });
  }
  deserializeAll(serialized) {
    return __async(this, null, function* () {
      return Promise.all(serialized.map(this.deserialize.bind(this)));
    });
  }
  deserializeAllAndAdd(serialized, options) {
    return __async(this, null, function* () {
      const deserialized = yield this.deserializeAll(serialized);
      this.addAll(deserialized, options);
    });
  }
  remove(pcdId) {
    return __async(this, null, function* () {
      this.pcds = this.pcds.filter((pcd) => pcd.id !== pcdId);
      this.folders = Object.fromEntries(
        Object.entries(this.folders).filter(([id]) => id !== pcdId)
      );
      this.recalculateAndEmitHash();
    });
  }
  deserializeAndAdd(serialized, options) {
    return __async(this, null, function* () {
      yield this.deserializeAllAndAdd([serialized], options);
    });
  }
  add(pcd, options) {
    this.addAll([pcd], options);
  }
  addAll(pcds, options) {
    const currentMap = new Map(this.pcds.map((pcd) => [pcd.id, pcd]));
    const toAddMap = new Map(pcds.map((pcd) => [pcd.id, pcd]));
    for (const [id, pcd] of Array.from(toAddMap.entries())) {
      if (currentMap.has(id) && !(options == null ? void 0 : options.upsert)) {
        throw new Error(`pcd with id ${id} is already in this collection`);
      }
      currentMap.set(id, pcd);
    }
    this.pcds = Array.from(currentMap.values());
    this.recalculateAndEmitHash();
  }
  size() {
    return this.pcds.length;
  }
  getAll() {
    return this.pcds;
  }
  getAllIds() {
    return this.getAll().map((pcd) => pcd.id);
  }
  getByIds(ids) {
    return this.pcds.filter(
      (pcd) => ids.find((id) => pcd.id === id) !== void 0
    );
  }
  /**
   * Generates a unique hash based on the contents. This hash changes whenever
   * the set of pcds, or the contents of the pcds changes.
   */
  getHash() {
    return __async(this, null, function* () {
      const allSerialized = yield this.serializeCollection();
      const hashed = yield (0, import_passport_crypto.getHash)(allSerialized);
      return hashed;
    });
  }
  getById(id) {
    return this.pcds.find((pcd) => pcd.id === id);
  }
  hasPCDWithId(id) {
    return this.getById(id) !== void 0;
  }
  getPCDsByType(type) {
    return this.pcds.filter((pcd) => pcd.type === type);
  }
  recalculateAndEmitHash() {
    this.getHash().then((newHash) => this.hashEmitter.emit(newHash));
  }
  static deserialize(packages, serialized) {
    return __async(this, null, function* () {
      var _a, _b;
      const parsed = JSON.parse(serialized);
      const collection = new PCDCollection(packages, []);
      const serializedPcdsList = (_a = parsed.pcds) != null ? _a : [];
      const parsedFolders = (_b = parsed.folders) != null ? _b : {};
      const pcds = yield Promise.all(
        serializedPcdsList.map(collection.deserialize.bind(collection))
      );
      collection.addAll(pcds, { upsert: true });
      collection.folders = parsedFolders;
      return collection;
    });
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  PATH_SEP,
  PCDActionType,
  PCDCollection,
  PCDPermissionType,
  escapePathSegment,
  getAllAncestors,
  getFoldersInFolder,
  getNameFromPath,
  getParentFolder,
  isAppendToFolderAction,
  isAppendToFolderPermission,
  isChild,
  isDeleteFolderAction,
  isDeleteFolderPermission,
  isFolderAncestor,
  isPCDFolderPermission,
  isReplaceInFolderAction,
  isReplaceInFolderPermission,
  isRootFolder,
  joinPath,
  matchActionToPermission,
  normalizePath,
  splitPath
});
