"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  PCDCrypto: () => PCDCrypto,
  arrayBufferToBase64: () => arrayBufferToBase64,
  arrayBufferToHexString: () => arrayBufferToHexString,
  arrayBufferToString: () => arrayBufferToString,
  base64ToArrayBuffer: () => base64ToArrayBuffer,
  base64ToHex: () => base64ToHex,
  getCrypto: () => getCrypto,
  getHash: () => getHash,
  hexStringToArrayBuffer: () => hexStringToArrayBuffer,
  hexToBase64: () => hexToBase64,
  ieOrEdge: () => ieOrEdge,
  isWebCryptoAvailable: () => isWebCryptoAvailable,
  passportDecrypt: () => passportDecrypt,
  passportEncrypt: () => passportEncrypt,
  stringToArrayBuffer: () => stringToArrayBuffer
});
module.exports = __toCommonJS(src_exports);

// src/libsodium.ts
var import_libsodium_wrappers_sumo = __toESM(require("libsodium-wrappers-sumo"));
function getSodium() {
  return __async(this, null, function* () {
    yield import_libsodium_wrappers_sumo.default.ready.catch((err) => {
      console.error("[Worker] libsodium error:", err);
    });
    return import_libsodium_wrappers_sumo.default;
  });
}

// src/utils.ts
var import_buffer = require("buffer");
var import_libsodium_wrappers_sumo2 = require("libsodium-wrappers-sumo");
function getCrypto() {
  const g = globalThis;
  if (g.crypto) {
    return g.crypto;
  } else {
    return require("crypto");
  }
}
function ieOrEdge() {
  return typeof document !== "undefined" && !!document.documentMode || /Edge/.test(navigator.userAgent);
}
function isWebCryptoAvailable() {
  return !ieOrEdge() && getCrypto().crypto && !!getCrypto().crypto.subtle;
}
function stringToArrayBuffer(string) {
  return (0, import_libsodium_wrappers_sumo2.from_string)(string);
}
function arrayBufferToString(arrayBuffer) {
  return (0, import_libsodium_wrappers_sumo2.to_string)(arrayBuffer);
}
function arrayBufferToHexString(arrayBuffer) {
  return (0, import_libsodium_wrappers_sumo2.to_hex)(import_buffer.Buffer.from(arrayBuffer));
}
function hexStringToArrayBuffer(hex) {
  return (0, import_libsodium_wrappers_sumo2.from_hex)(hex);
}
function base64ToArrayBuffer(base64) {
  return (0, import_libsodium_wrappers_sumo2.from_base64)(base64, import_libsodium_wrappers_sumo2.base64_variants.ORIGINAL);
}
function arrayBufferToBase64(arrayBuffer) {
  return (0, import_libsodium_wrappers_sumo2.to_base64)(import_buffer.Buffer.from(arrayBuffer), import_libsodium_wrappers_sumo2.base64_variants.ORIGINAL);
}
function hexToBase64(hex) {
  return (0, import_libsodium_wrappers_sumo2.to_base64)((0, import_libsodium_wrappers_sumo2.from_hex)(hex), import_libsodium_wrappers_sumo2.base64_variants.ORIGINAL);
}
function base64ToHex(base64) {
  return (0, import_libsodium_wrappers_sumo2.to_hex)((0, import_libsodium_wrappers_sumo2.from_base64)(base64, import_libsodium_wrappers_sumo2.base64_variants.ORIGINAL));
}

// src/passportCrypto.ts
var PCDCrypto = class {
  static newInstance() {
    return __async(this, null, function* () {
      const sodium = yield getSodium();
      return new PCDCrypto(sodium);
    });
  }
  constructor(sodium) {
    this.sodium = sodium;
  }
  cryptoHash(str) {
    return arrayBufferToHexString(this.sodium.crypto_hash(str));
  }
  generateRandomKey(bits = 256) {
    const bytes = bits / 8;
    const arrayBuffer = getCrypto().getRandomValues(new Uint8Array(bytes));
    return arrayBufferToHexString(arrayBuffer);
  }
  /**
   * Combines generateSalt and argon2 function, returns both a random salt
   * and the resulting 32-byte encryption key.
   */
  generateSaltAndEncryptionKey(password) {
    const salt = this.generateSalt();
    const key = this.argon2(password, salt, 32);
    return { key, salt };
  }
  generateSalt(length = this.sodium.crypto_pwhash_SALTBYTES) {
    if (length < this.sodium.crypto_pwhash_SALTBYTES) {
      throw Error(
        `Salt must be at least ${this.sodium.crypto_pwhash_SALTBYTES}`
      );
    }
    const buffer = this.sodium.randombytes_buf(length);
    return arrayBufferToHexString(buffer);
  }
  argon2(password, salt, length = 32) {
    const result = this.sodium.crypto_pwhash(
      length,
      stringToArrayBuffer(password),
      hexStringToArrayBuffer(salt),
      // `oplimit` represents the maximum amount of computations to perform
      // for generating a key. `crypto_pwhash_OPSLIMIT_INTERACTIVE` is recommended
      // for intereactive, online applications such as Zupass.
      // Source: https://libsodium.gitbook.io/doc/password_hashing/default_phf
      this.sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
      // `memlimit` represents the maximum amount of RAM in bytes thet function
      // will use. It is required to use the same values for `opslimit` and `memlimit`.
      this.sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
      this.sodium.crypto_pwhash_ALG_DEFAULT,
      "hex"
    );
    return result;
  }
  xchacha20Encrypt(plaintext, nonce, key, assocData) {
    if (nonce.length !== 48) {
      throw Error("Nonce must be 24 bytes");
    }
    const arrayBuffer = this.sodium.crypto_aead_xchacha20poly1305_ietf_encrypt(
      plaintext,
      assocData || null,
      null,
      hexStringToArrayBuffer(nonce),
      hexStringToArrayBuffer(key)
    );
    return arrayBufferToBase64(arrayBuffer);
  }
  xchacha20Decrypt(ciphertext, nonce, key, assocData) {
    if (nonce.length !== 48) {
      throw Error("Nonce must be 24 bytes");
    }
    try {
      return this.sodium.crypto_aead_xchacha20poly1305_ietf_decrypt(
        null,
        base64ToArrayBuffer(ciphertext),
        assocData || null,
        hexStringToArrayBuffer(nonce),
        hexStringToArrayBuffer(key),
        "text"
      );
    } catch (e) {
      return null;
    }
  }
  randombytesDeterministic(length, seed) {
    try {
      return this.sodium.randombytes_buf_deterministic(length, seed);
    } catch (e) {
      return null;
    }
  }
};

// src/endToEndEncryption.ts
var cryptoPromise = PCDCrypto.newInstance();
function getHash(str) {
  return __async(this, null, function* () {
    const crypto = yield cryptoPromise;
    const hashed = crypto.cryptoHash(str);
    return hashed;
  });
}
function passportEncrypt(data, encryptionKey) {
  return __async(this, null, function* () {
    const crypto = yield cryptoPromise;
    const nonce = crypto.generateRandomKey(192);
    const ciphertext = crypto.xchacha20Encrypt(data, nonce, encryptionKey, "abc");
    return {
      nonce,
      ciphertext
    };
  });
}
function passportDecrypt(encryptedStorage, encryptionKey) {
  return __async(this, null, function* () {
    const crypto = yield cryptoPromise;
    const plaintext = crypto.xchacha20Decrypt(
      encryptedStorage.ciphertext,
      encryptedStorage.nonce,
      encryptionKey,
      "abc"
    );
    if (!plaintext) {
      throw new Error("could not decrypt storage");
    }
    return plaintext;
  });
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  PCDCrypto,
  arrayBufferToBase64,
  arrayBufferToHexString,
  arrayBufferToString,
  base64ToArrayBuffer,
  base64ToHex,
  getCrypto,
  getHash,
  hexStringToArrayBuffer,
  hexToBase64,
  ieOrEdge,
  isWebCryptoAvailable,
  passportDecrypt,
  passportEncrypt,
  stringToArrayBuffer
});
