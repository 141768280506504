"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  FieldLabel: () => FieldLabel,
  HiddenText: () => HiddenText,
  HiddenTextContainer: () => HiddenTextContainer,
  ImageZoom: () => ImageZoom,
  LinkButton: () => LinkButton,
  QR: () => QR,
  QRDisplay: () => QRDisplay,
  QRDisplayWithRegenerateAndStorage: () => QRDisplayWithRegenerateAndStorage,
  Separator: () => Separator,
  Spacer: () => Spacer,
  TextContainer: () => TextContainer,
  decodeQRPayload: () => decodeQRPayload,
  encodeQRPayload: () => encodeQRPayload
});
module.exports = __toCommonJS(src_exports);

// src/Core.tsx
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function Spacer({
  w,
  h
}) {
  const width = w && `${w}px`;
  const height = h && `${h}px`;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)("div", { style: { width, height, userSelect: "none" } });
}
var Separator = import_styled_components.default.div`
  box-sizing: border-box;
  background-color: var(--primary-lite);
  width: 100%;
  height: 2px;
  border-radius: 99px;
  margin: 16px 0px;
  user-select: none;
`;
var FieldLabel = import_styled_components.default.span`
  font-weight: bold;
`;
var LinkButton = import_styled_components.default.a`
  color: #e6a50f;
  cursor: pointer;
  text-decoration: none;
`;

// src/HiddenText.tsx
var import_react = require("react");
var import_styled_components2 = __toESM(require("styled-components"));
var import_jsx_runtime2 = require("react/jsx-runtime");
function HiddenText({ text }) {
  const [visible, setVisible] = (0, import_react.useState)(false);
  const onRevealClick = (0, import_react.useCallback)(() => {
    setVisible(true);
  }, []);
  if (visible) {
    return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(TextContainer, { children: text });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(HiddenTextContainer, { onClick: onRevealClick, children: "tap to reveal" });
}
var TextContainer = import_styled_components2.default.div`
  border: 2px solid var(--primary-lite);
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
`;
var HiddenTextContainer = import_styled_components2.default.div`
  border: 2px solid var(--primary-lite);
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

// src/ImageZoom.tsx
var import_medium_zoom = __toESM(require("medium-zoom"));
var import_react2 = require("react");
var import_jsx_runtime3 = require("react/jsx-runtime");
function ImageZoom(_a) {
  var _b = _a, { options } = _b, props = __objRest(_b, ["options"]);
  const zoomRef = (0, import_react2.useRef)(null);
  const getZoom = (0, import_react2.useCallback)(() => {
    if (zoomRef.current === null) {
      zoomRef.current = (0, import_medium_zoom.default)(options);
    }
    return zoomRef.current;
  }, [options]);
  const attachZoom = (0, import_react2.useCallback)(
    (image) => {
      const zoom = getZoom();
      if (image) {
        zoom.attach(image);
      } else {
        zoom.detach();
      }
    },
    [getZoom]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("img", __spreadProps(__spreadValues({}, props), { ref: attachZoom }));
}

// src/QR.tsx
var import_pako = require("pako");
var import_qr_image = __toESM(require("qr-image"));
var import_react3 = require("react");
var import_styled_components3 = __toESM(require("styled-components"));
var import_jsx_runtime4 = require("react/jsx-runtime");
function encodeQRPayload(unencoded) {
  console.log(`encoding payload with length ${unencoded.length}`);
  const compressedData = (0, import_pako.gzip)(unencoded);
  const base64CompressedData = Buffer.from(compressedData).toString("base64");
  console.log(
    `Compressed: ${compressedData.length}, base64: ${base64CompressedData.length}`
  );
  return base64CompressedData;
}
function decodeQRPayload(encoded) {
  const buffer = Buffer.from(encoded, "base64");
  const unzippedBuffer = Buffer.from((0, import_pako.ungzip)(buffer));
  const decodedBuffer = unzippedBuffer.toString("utf8");
  return decodedBuffer;
}
function QRDisplayWithRegenerateAndStorage({
  generateQRPayload,
  maxAgeMs,
  uniqueId,
  loadingLogo,
  loadedLogo,
  fgColor,
  bgColor
}) {
  const regenerateAfterMs = maxAgeMs * 2 / 3;
  const [savedState, setSavedState] = (0, import_react3.useState)(() => {
    const savedState2 = JSON.parse(
      localStorage[uniqueId] || "{}"
    );
    const { timestamp, payload } = savedState2;
    if (timestamp != null && Date.now() - timestamp < maxAgeMs && payload !== void 0) {
      return { timestamp, payload };
    }
    return void 0;
  });
  const maybeGenerateQR = (0, import_react3.useCallback)(() => __async(this, null, function* () {
    const timestamp = Date.now();
    if (savedState && timestamp - savedState.timestamp < regenerateAfterMs) {
      return;
    }
    const newData = yield generateQRPayload();
    const newSavedState = { timestamp, payload: newData };
    localStorage[uniqueId] = JSON.stringify(newSavedState);
    setSavedState(newSavedState);
  }), [generateQRPayload, regenerateAfterMs, savedState, uniqueId]);
  (0, import_react3.useEffect)(() => {
    maybeGenerateQR();
    const interval = setInterval(maybeGenerateQR, maxAgeMs / 10);
    return () => clearInterval(interval);
  }, [maxAgeMs, maybeGenerateQR, savedState]);
  const logoOverlay = (0, import_react3.useMemo)(() => {
    return savedState ? loadedLogo : loadingLogo;
  }, [loadedLogo, loadingLogo, savedState]);
  (0, import_react3.useEffect)(() => {
    console.log("[QR] rendering ", savedState);
  }, [savedState, savedState == null ? void 0 : savedState.payload]);
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
    QRDisplay,
    {
      logoOverlay,
      value: savedState == null ? void 0 : savedState.payload,
      fgColor,
      bgColor,
      saved: !!savedState
    }
  );
}
function QRDisplay({
  value,
  logoOverlay,
  fgColor,
  bgColor,
  saved
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsxs)(QRWrap, { saved, children: [
    value !== void 0 && /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
      QR,
      {
        value,
        bgColor: bgColor != null ? bgColor : "#ffffff",
        fgColor: fgColor != null ? fgColor : "#000000"
      }
    ),
    logoOverlay
  ] });
}
var QRWrap = import_styled_components3.default.div`
  ${({ saved }) => saved ? import_styled_components3.css`` : import_styled_components3.css``}
  height: 0;
  padding-bottom: 100%;
  position: relative;
  margin: 0 auto;
  width: 100%;
`;
function QR({
  value,
  fgColor,
  bgColor
}) {
  const [svgObject, setSvgObject] = (0, import_react3.useState)();
  (0, import_react3.useEffect)(() => {
    const svgObject2 = import_qr_image.default.svgObject(value, "L");
    setSvgObject(svgObject2);
  }, [bgColor, fgColor, value]);
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Container, { children: svgObject && /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
    "svg",
    {
      viewBox: `0 0 ${svgObject.size} ${svgObject.size}`,
      preserveAspectRatio: "none",
      children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
        "path",
        {
          width: "100%",
          height: "100%",
          d: svgObject.path,
          fill: fgColor
        }
      )
    }
  ) });
}
var Container = import_styled_components3.default.div`
  width: 100% !important;
  height: 100% !important;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  FieldLabel,
  HiddenText,
  HiddenTextContainer,
  ImageZoom,
  LinkButton,
  QR,
  QRDisplay,
  QRDisplayWithRegenerateAndStorage,
  Separator,
  Spacer,
  TextContainer,
  decodeQRPayload,
  encodeQRPayload
});
