"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  RSAImagePCD: () => RSAImagePCD,
  RSAImagePCDPackage: () => RSAImagePCDPackage,
  RSAPCDTypeName: () => RSAPCDTypeName,
  deserialize: () => deserialize,
  getDisplayOptions: () => getDisplayOptions,
  getPublicKey: () => getPublicKey,
  prove: () => prove,
  serialize: () => serialize,
  verify: () => verify
});
module.exports = __toCommonJS(src_exports);

// src/RSAImagePCD.ts
var import_pcd_types = require("@pcd/pcd-types");
var import_rsa_pcd = require("@pcd/rsa-pcd");
var import_json_bigint = __toESM(require("json-bigint"));
var import_uuid = require("uuid");

// src/CardBody.tsx
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
function RSAImageCardBody({ pcd }) {
  const imageData = JSON.parse(pcd.proof.rsaPCD.claim.message);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Container, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("img", { src: imageData.url }) });
}
var Container = import_styled_components.default.div`
  padding: 16px;
  overflow: hidden;
  width: 100%;

  img {
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
  }
`;

// src/RSAImagePCD.ts
var RSAPCDTypeName = "rsa-image-pcd";
var RSAImagePCD = class {
  constructor(id, claim, proof) {
    this.type = RSAPCDTypeName;
    this.id = id;
    this.claim = claim;
    this.proof = proof;
  }
};
function prove(args) {
  return __async(this, null, function* () {
    var _a;
    if (args.url.value == null) {
      throw new Error("missing url");
    }
    if (args.title.value == null) {
      throw new Error("missing title");
    }
    if (args.privateKey.value == null) {
      throw new Error("missing private key");
    }
    const proof = yield import_rsa_pcd.RSAPCDPackage.prove({
      id: args.id,
      privateKey: args.privateKey,
      signedMessage: {
        argumentType: import_pcd_types.ArgumentTypeName.String,
        value: JSON.stringify({
          url: args.url.value,
          title: args.title.value
        })
      }
    });
    const id = (_a = args.id.value) != null ? _a : (0, import_uuid.v4)();
    return new RSAImagePCD(id, {}, { rsaPCD: proof });
  });
}
function verify(pcd) {
  return __async(this, null, function* () {
    try {
      const valid = yield import_rsa_pcd.RSAPCDPackage.verify(pcd.proof.rsaPCD);
      return valid;
    } catch (e) {
      return false;
    }
  });
}
function serialize(pcd) {
  return __async(this, null, function* () {
    const serializedRSAPCD = yield import_rsa_pcd.RSAPCDPackage.serialize(pcd.proof.rsaPCD);
    return {
      type: RSAPCDTypeName,
      pcd: (0, import_json_bigint.default)().stringify({
        id: pcd.id,
        rsaPCD: serializedRSAPCD
      })
    };
  });
}
function deserialize(serialized) {
  return __async(this, null, function* () {
    const deserializedWrapper = (0, import_json_bigint.default)().parse(serialized);
    const deserializedRSAPCD = yield import_rsa_pcd.RSAPCDPackage.deserialize(
      deserializedWrapper.rsaPCD.pcd
    );
    return new RSAImagePCD(
      deserializedWrapper.id,
      {},
      { rsaPCD: deserializedRSAPCD }
    );
  });
}
function getDisplayOptions(pcd) {
  const imageData = JSON.parse(pcd.proof.rsaPCD.claim.message);
  const header = imageData.title;
  return {
    header,
    displayName: "image-" + pcd.id.substring(0, 4)
  };
}
var RSAImagePCDPackage = {
  name: RSAPCDTypeName,
  renderCardBody: RSAImageCardBody,
  getDisplayOptions,
  prove,
  verify,
  serialize,
  deserialize
};

// src/utils.ts
var import_node_rsa = __toESM(require("node-rsa"));
function getPublicKey(pcd) {
  var _a, _b, _c;
  const encodedPublicKey = (_c = (_b = (_a = pcd == null ? void 0 : pcd.proof) == null ? void 0 : _a.rsaPCD) == null ? void 0 : _b.proof) == null ? void 0 : _c.publicKey;
  if (!encodedPublicKey) {
    return void 0;
  }
  try {
    const key = new import_node_rsa.default(encodedPublicKey, "public");
    return key;
  } catch (e) {
    console.log("failed to deserialize key");
  }
  return void 0;
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  RSAImagePCD,
  RSAImagePCDPackage,
  RSAPCDTypeName,
  deserialize,
  getDisplayOptions,
  getPublicKey,
  prove,
  serialize,
  verify
});
