"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  BABY_JUB_NEGATIVE_ONE: () => BABY_JUB_NEGATIVE_ONE,
  ONE_HOUR_MS: () => ONE_HOUR_MS,
  PSEUDONYM_TO_EMOJI: () => PSEUDONYM_TO_EMOJI,
  ZUPASS_GITHUB_REPOSITORY_URL: () => ZUPASS_GITHUB_REPOSITORY_URL,
  ZUPASS_SENDER_EMAIL: () => ZUPASS_SENDER_EMAIL,
  ZUPASS_SUPPORT_EMAIL: () => ZUPASS_SUPPORT_EMAIL,
  babyJubIsNegativeOne: () => babyJubIsNegativeOne,
  bigIntToPseudonymEmoji: () => bigIntToPseudonymEmoji,
  bigIntToPseudonymName: () => bigIntToPseudonymName,
  bigintToPseudonym: () => bigintToPseudonym,
  booleanToBigInt: () => booleanToBigInt,
  decStringToBigIntToUuid: () => decStringToBigIntToUuid,
  encodeAnonMessageIdAndReaction: () => encodeAnonMessageIdAndReaction,
  fromHexString: () => fromHexString,
  generateSnarkMessageHash: () => generateSnarkMessageHash,
  getAnonTopicNullifier: () => getAnonTopicNullifier,
  getErrorMessage: () => getErrorMessage,
  getMessageWatermark: () => getMessageWatermark,
  getRandomValues: () => getRandomValues,
  hexToBigInt: () => hexToBigInt,
  isBrowser: () => isBrowser,
  isFulfilled: () => isFulfilled,
  isNode: () => isNode,
  isWebAssemblySupported: () => isWebAssemblySupported,
  numberToBigInt: () => numberToBigInt,
  requireDefinedParameter: () => requireDefinedParameter,
  sleep: () => sleep,
  toHexString: () => toHexString,
  uuidToBigInt: () => uuidToBigInt
});
module.exports = __toCommonJS(src_exports);

// src/Constants.ts
var ONE_HOUR_MS = 1e3 * 60 * 60;
var ZUPASS_SUPPORT_EMAIL = "support@zupass.org";
var ZUPASS_SENDER_EMAIL = "noreply@zupass.org";
var ZUPASS_GITHUB_REPOSITORY_URL = "https://github.com/proofcarryingdata/zupass";

// src/Environment.ts
function isNode() {
  return new Function("try {return this===global}catch(e){ return false}")();
}
function isBrowser() {
  return new Function("try {return this===window}catch(e){ return false}")();
}
function isWebAssemblySupported() {
  try {
    if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
      const module2 = new WebAssembly.Module(
        Uint8Array.of(0, 97, 115, 109, 1, 0, 0, 0)
      );
      if (module2 instanceof WebAssembly.Module) {
        return new WebAssembly.Instance(module2) instanceof WebAssembly.Instance;
      }
      return false;
    }
    return false;
  } catch (e) {
    return false;
  }
}

// src/CryptoHelpers.ts
var crypto;
function initCryptoAPI() {
  if (!crypto) {
    if (isBrowser()) {
      crypto = globalThis.crypto;
    } else if (isNode()) {
      const { webcrypto } = require("crypto");
      crypto = webcrypto;
    } else {
      throw new Error("Crypto API is not defined");
    }
  }
  return crypto;
}
function getRandomValues(numberOfBytes) {
  const crypto2 = initCryptoAPI();
  return crypto2.getRandomValues(new Uint8Array(numberOfBytes));
}

// src/Errors.ts
function getErrorMessage(e) {
  if (e instanceof Error) {
    return e.message;
  }
  return e + "";
}
function requireDefinedParameter(parameter, parameterName) {
  if (typeof parameter === "undefined") {
    throw new Error(`${parameterName} must be defined`);
  }
}

// src/NumericRepresentation.ts
var import_uuid = require("uuid");
function toHexString(bytes) {
  return Buffer.from(bytes).toString("hex");
}
function fromHexString(hexString) {
  return Buffer.from(hexString, "hex");
}
function decStringToBigIntToUuid(value) {
  let hexStr = BigInt(value).toString(16);
  while (hexStr.length < 32)
    hexStr = "0" + hexStr;
  const buf = Buffer.from(hexStr, "hex");
  return (0, import_uuid.stringify)(buf);
}
function uuidToBigInt(v) {
  const bytes = (0, import_uuid.parse)(v);
  const hex = "0x" + Buffer.from(bytes).toString("hex");
  return BigInt(hex);
}
function numberToBigInt(v) {
  return BigInt(v);
}
function hexToBigInt(v) {
  if (!v.startsWith("0x")) {
    v = "0x" + v;
  }
  return BigInt(v);
}
function booleanToBigInt(v) {
  return BigInt(v ? 1 : 0);
}

// src/Pseudonyms.ts
var PSEUDONYM_TO_EMOJI = {
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0D\u1D0F\u0274\u1D0B\u1D07\u028F": "\u{1F412}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u0299\u1D07\u1D00\u0280": "\u{1F43B}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D05\u1D1C\u1D04\u1D0B": "\u{1F986}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0F\u1D21\u029F": "\u{1F989}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u029C\u1D0F\u0280\uA731\u1D07": "\u{1F434}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u0299\u1D07\u1D07": "\u{1F41D}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D21\u1D0F\u0280\u1D0D": "\u{1FAB1}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u0299\u1D1C\u1D1B\u1D1B\u1D07\u0280\uA730\u029F\u028F": "\u{1F98B}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA731\u0274\u1D00\u026A\u029F": "\u{1F40C}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D1B\u1D1C\u0280\u1D1B\u029F\u1D07": "\u{1F422}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u029F\u026A\u1D22\u1D00\u0280\u1D05": "\u{1F98E}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA730\u0280\u1D0F\u0262": "\u{1F438}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D1B \u0280\u1D07x": "\u{1F996}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0F\u1D04\u1D1B\u1D0F\u1D18\u1D1C\uA731": "\u{1F419}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA731\u029C\u0280\u026A\u1D0D\u1D18": "\u{1F990}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u0280\u1D00\u0299": "\u{1F980}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA730\u026A\uA731\u029C": "\u{1F41F}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D05\u1D0F\u029F\u1D18\u029C\u026A\u0274": "\u{1F42C}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D21\u029C\u1D00\u029F\u1D07": "\u{1F433}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D22\u1D07\u0299\u0280\u1D00": "\u{1F993}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0F\u0280\u1D00\u0274\u0262\u1D1C\u1D1B\u1D00\u0274": "\u{1F9A7}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D07\u029F\u1D07\u1D18\u029C\u1D00\u0274\u1D1B": "\u{1F418}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u1D00\u1D0D\u1D07\u029F": "\u{1F42B}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u0262\u026A\u0280\u1D00\uA730\uA730\u1D07": "\u{1F992}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0B\u1D00\u0274\u0262\u1D00\u0280\u1D0F\u1D0F": "\u{1F998}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u1D0F\u1D21": "\u{1F404}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA731\u029C\u1D07\u1D07\u1D18": "\u{1F411}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u1D00\u1D1B": "\u{1F408}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u029C\u026A\u1D04\u1D0B\u1D07\u0274": "\u{1F413}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D18\u1D00\u0280\u0280\u1D0F\u1D1B": "\u{1F99C}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA730\u029F\u1D00\u1D0D\u026A\u0274\u0262\u1D0F": "\u{1F9A9}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA731\u1D0B\u1D1C\u0274\u1D0B": "\u{1F9A8}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u0280\u1D00\u1D04\u1D04\u1D0F\u1D0F\u0274": "\u{1F99D}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA731\u029F\u1D0F\u1D1B\u029C": "\u{1F9A5}",
  "\u1D00\u0274\u1D0F\u0274 \u1D07. \u1D0D\u1D0F\u1D1C\uA731\u1D07": "\u{1F400}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u029C\u1D07\u1D05\u0262\u1D07\u029C\u1D0F\u0262": "\u{1F994}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u029F\u1D0F\u0262": "\u{1FAB5}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0D\u1D1C\uA731\u029C\u0280\u1D0F\u1D0F\u1D0D": "\u{1F344}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u1D00\u1D04\u1D1B\u1D1C\uA731": "\u{1F335}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \uA731\u1D1C\u0274\uA730\u029F\u1D0F\u1D21\u1D07\u0280": "\u{1F33B}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u1D0F\u0280\u0274": "\u{1F33D}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D18\u1D07\u1D00\u0274\u1D1C\u1D1B": "\u{1F95C}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u0299\u1D00\u0262\u1D1C\u1D07\u1D1B\u1D1B\u1D07": "\u{1F956}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D04\u1D00\u0274\u1D0F\u1D07": "\u{1F6F6}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u028F\u1D00\u1D0D": "\u{1F360}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D18\u1D1C\u1D0D\u1D18\u1D0B\u026A\u0274": "\u{1F383}",
  "\u1D00\u0274\u1D0F\u0274\u028F\u1D0D\u1D0F\u1D1C\uA731 \u1D0D\u1D0F\u1D0F\u0274": "\u{1F31A}"
};
function bigIntToPseudonymEmoji(input) {
  const key = Number(input % BigInt(Object.keys(PSEUDONYM_TO_EMOJI).length));
  return Object.values(PSEUDONYM_TO_EMOJI)[key];
}
function bigIntToPseudonymName(input) {
  const key = Number(input % BigInt(Object.keys(PSEUDONYM_TO_EMOJI).length));
  return Object.keys(PSEUDONYM_TO_EMOJI)[key];
}
function bigintToPseudonym(input) {
  return `${bigIntToPseudonymEmoji(input)} ${bigIntToPseudonymName(input)}`;
}

// src/SNARKHelpers.ts
var import_js_sha256 = require("js-sha256");
var BABY_JUB_NEGATIVE_ONE = BigInt(
  "21888242871839275222246405745257275088548364400416034343698204186575808495616"
);
function babyJubIsNegativeOne(value) {
  const bigintValue = BigInt(value);
  return bigintValue === BABY_JUB_NEGATIVE_ONE || bigintValue === BigInt(-1);
}
function generateSnarkMessageHash(signal) {
  return BigInt("0x" + (0, import_js_sha256.sha256)(signal)) >> BigInt(8);
}

// src/promises.ts
function isFulfilled(result) {
  return result.status === "fulfilled";
}
function sleep(ms) {
  return __async(this, null, function* () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms != null ? ms : 1);
    });
  });
}

// src/telegram.ts
var import_js_sha2562 = require("js-sha256");
function getMessageWatermark(message) {
  const hashed = (0, import_js_sha2562.sha256)(message).substring(0, 16);
  return BigInt("0x" + hashed);
}
function encodeAnonMessageIdAndReaction(anonMessageId, reaction) {
  return `REACT:${anonMessageId}:${reaction}`;
}
var getAnonTopicNullifier = () => {
  return BigInt("0x" + (0, import_js_sha2562.sha256)("anon_message").substring(0, 16));
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  BABY_JUB_NEGATIVE_ONE,
  ONE_HOUR_MS,
  PSEUDONYM_TO_EMOJI,
  ZUPASS_GITHUB_REPOSITORY_URL,
  ZUPASS_SENDER_EMAIL,
  ZUPASS_SUPPORT_EMAIL,
  babyJubIsNegativeOne,
  bigIntToPseudonymEmoji,
  bigIntToPseudonymName,
  bigintToPseudonym,
  booleanToBigInt,
  decStringToBigIntToUuid,
  encodeAnonMessageIdAndReaction,
  fromHexString,
  generateSnarkMessageHash,
  getAnonTopicNullifier,
  getErrorMessage,
  getMessageWatermark,
  getRandomValues,
  hexToBigInt,
  isBrowser,
  isFulfilled,
  isNode,
  isWebAssemblySupported,
  numberToBigInt,
  requireDefinedParameter,
  sleep,
  toHexString,
  uuidToBigInt
});
